.go-to-top-button {
    @include apply-screen-size(desktop-sm) {
        right: var(--horizontal-gap-large);
        bottom: var(--horizontal-gap-large);
        width: 45px;
        height: 45px;
    }

    position: fixed;
    right: var(--horizontal-gap);
    bottom: var(--horizontal-gap);
    width: 30px;
    height: 30px;
    padding: 0;
    border-radius: 100%;
    background: transparent;
    background: var(--color-primary);
    color: var(--color-base);
    transition: opacity 0.5s;
    appearance: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}
