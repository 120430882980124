@import '_variables.scss';
@import '_mixins.scss';

$font-stack-system:
    system-ui, /* latest browsers and all the future system fonts */
    -apple-system, /* old versions of Safari on Mac OS and iOS */
    BlinkMacSystemFont, /* old versions of Chrome on Mac OS and iOS */
    'Segoe UI', /* all browsers on Windows 7 or later */
    Roboto, /* Android and Chrome OS */
    Ubuntu, /* Ubuntu */
    'Helvetica Neue', /* old browsers on Mac OS 10.10 (Yosemite) */
    Arial, /* very old Windows */
    sans-serif,
    'Apple Color Emoji', /* Mac OS */
    'Segoe UI Emoji', /* Windows 10 */
    'Segoe UI Symbol', /* Windows 7 and 8 */
    'Noto Color Emoji' !default; /* Android and Linux */


/* stylelint-disable */
// use this mixin carefully. There are high chances that you don't need it, cause we tend to use custom font on BW
@mixin font-family( $family ) {
    $font-families-map: (
        // don't add quotes around the key!
        Circular20: #{'Circular20', $font-stack-system},
        system: $font-stack-system,
    );

    @if map-has-key($font-families-map, $family) {
        font-family: map-get($font-families-map, $family);
    }

    @else {
        @warn 'No key `#{$family}` found for map $font-families-map in mixin @font-family()';
    }
}
@mixin font-weight( $family, $weight ) {
    $family-weights-map: (
        // don't add quotes around the keys!
        Circular20: ( book: 500, medium: 600, black: 800 ),
        system: ( light: 300, regular: 400, medium: 600, semibold: 600, bold: 600 ),
    );

    @if map-has-key($family-weights-map, $family) {
        $weights-map: map-get($family-weights-map, $family);

        @if map-has-key($weights-map, $weight) {
            font-weight: map-get($weights-map, $weight);
        }

        @else {
            @warn 'No key `#{$weight}` for `#{$family}` found for map $weights-map in mixin @font-weight()';
        }
    }

    @else {
        @warn 'No key `#{$family}` found for map $family-weights-map in mixin @font-weight()';
    }
}
@mixin header-1() {
    @include font-family($token-font-family-header-1);
    @include font-weight($token-font-family-header-1, $token-font-weight-header-1);

    font-size: $token-font-size-header-1;
    line-height: relative-line-height($token-font-size-header-1, $token-line-height-header-1);
}
@mixin header-2() {
    @include font-family($token-font-family-header-2);
    @include font-weight($token-font-family-header-2, $token-font-weight-header-2);

    font-size: $token-font-size-header-2;
    line-height: relative-line-height($token-font-size-header-2, $token-line-height-header-2);
}
@mixin action() {
    @include font-family($token-font-family-action);
    @include font-weight($token-font-family-action, $token-font-weight-action);

    font-size: $token-font-size-action;
    line-height: relative-line-height($token-font-size-action, $token-line-height-action);
}
@mixin p-1($font-weight: null) {
    @include font-family($token-font-family-paragraph-1);

    @if ($font-weight) {
        @include font-weight($token-font-family-paragraph-1, $font-weight);
    }

    font-size: $token-font-size-paragraph-1;
    line-height: relative-line-height($token-font-size-paragraph-1, $token-line-height-paragraph-1);
}
@mixin p-2($font-weight: null) {
    @include font-family($token-font-family-paragraph-2);

    @if ($font-weight) {
        font-weight: $font-weight;
    }

    font-size: $token-font-size-paragraph-2;
    line-height: relative-line-height($token-font-size-paragraph-2, $token-line-height-paragraph-2);
}
@mixin p-3($font-weight: null) {
    @include font-family($token-font-family-paragraph-3);

    @if ($font-weight) {
        font-weight: $font-weight;
    }

    font-size: $token-font-size-paragraph-3;
    line-height: relative-line-height($token-font-size-paragraph-3, $token-line-height-paragraph-3);
}
/* stylelint-enable */
