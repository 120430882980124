//
// Call to action block
//
// @maintainer: Dmitrii Kuznetsov (d.kuznetsov@corp.badoo.com)
//

// @import '_variables.scss';
// @import '_mixins.scss';
// @import '_typography.scss';

.cta-box {
    --cta-box-title-size: #{$ui-cta-box-title-size};
    --cta-box-title-line-height: #{$ui-cta-box-title-line-height};
    --cta-box-text-size: #{$ui-cta-box-text-size};
    --cta-box-text-line-height: #{$ui-cta-box-text-line-height};
    --cta-box-max-width: #{$ui-cta-box-max-width};

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex: 1 0 auto;
    align-self: stretch; // in edge min-height: 100% doesn't work, so we may need it for cases with flex parent
    width: 100%;
    min-height: 100%;
}

.cta-box__inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1 0 auto;
    max-width: var(--cta-box-max-width);
    width: 100%;
    margin: 0 auto;
}

.cta-box__media {
    color: $token-color-black;

    > img,
    > svg {
        display: block;
    }
}

.cta-box__title {
    @include font-weight($token-font-family-header-1, $token-font-weight-header-1);

    width: 100%;
    margin: 0;
    color: $token-color-black;
    font-size: var(--cta-box-title-size);
    line-height: var(--cta-box-title-line-height);
}

.cta-box__text {
    width: 100%;
    margin: 0;
    color: $token-color-black;
    overflow-wrap: break-word;
    font-size: var(--cta-box-text-size);
    line-height: var(--cta-box-text-line-height);
}

.cta-box__text,
.cta-box__gdpr-text {
    a,
    .link {
        @include simpleLink($token-color-black, $token-ui-link-color-hover);
    }

    p + p {
        margin-top: 8px;
    }
}

.cta-box__gdpr-text,
.cta-box__hint {
    margin-top: $ui-cta-box-hint-gap;

    // in case when hint or gdpr text is placed before actions, we use the same top gap to separate them
    & + .cta-box__actions {
        margin-top: $ui-cta-box-hint-gap;
    }
}

.cta-box__actions {
    margin-top: $ui-cta-box-actions-top-gap;
}

.cta-box__content {
    display: flex;
    justify-content: center;
    width: 100%;
}

// TODO: to much adjacent selectors.

.cta-box__media + .cta-box__title,
.cta-box__media + .cta-box__text {
    margin-top: $ui-cta-box-media-bottom-gap;
}

.cta-box__title + .cta-box__text {
    margin-top: $ui-cta-box-text-top-gap;
}

.cta-box__text + .cta-box__actions,
.cta-box__title + .cta-box__actions,
.cta-box__media + .cta-box__actions {
    margin-top: $ui-cta-box-actions-top-gap;
}

//.cta-box__title + .cta-box__controls {
//    margin-top: 24px;
//}

// Modifiers

.cta-box--white {
    .cta-box__media,
    .cta-box__title {
        color: $token-color-white;
    }

    .cta-box__text {
        color: $token-color-white;

        a,
        .link {
            @include simpleLink($token-color-white, $token-color-white);
        }
    }
}

.cta-box--hives {
    .cta-box__content {
        margin: 48px 0;
        text-align: left;
    }

    padding-bottom: $token-spacing-xxlg;
}