// @import '_variables.scss';

.check-list__item {
    display: flex;
    align-items: flex-start;
    margin-bottom: $token-spacing-xlg;
    color: $token-color-black;

    &:last-child {
        margin-bottom: 0;
    }
}

.check-list__item-header {
    margin-bottom: $token-spacing-md;
}

.check-list__item-icon {
    width: 18px;
    height: $token-line-height-header-2;
    margin-right: 22px;
    font-weight: bold;
}