.apply {
    padding-top: 84px;

    .content {
        padding: 100px 10vw;
        width: 100%;
        max-width: 1400px;
        box-sizing: content-box;
    }
}

.apply-ctas {
    gap: 16px;
}

.apply-ctas a {
    color: var(--color-black);
    border: 1px solid var(--color-black);
    border-radius: 24px;
    padding: 10px 20px;
    min-width: 140px;
    text-align: center;
    font-size: 20px;
    line-height: 20px;
}
.apply-ctas a:hover {
    background-color: var(--color-yellow);
    /*     border: 1px solid var(--color-yellow); */
}

@media only screen and (max-width: 600px) {
    .apply {
        padding-top: 48px;

        .content {
            padding: 16px 16px 48px;
        }
    }

    .apply-ctas {
        margin-top: 32px;
        gap: 16px;
    }
}
