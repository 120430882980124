// @import '_variables.scss';

.button-group {
    --button-group-vertical-vgap: #{$ui-button-group-item-gap-vertical};
    --button-group-horizontal-vgap: #{$ui-button-group-item-gap-vertical};
    --button-group-horizontal-hgap: #{$ui-button-group-item-gap-horizontal};

    display: block;
}

.button-group__item {
    display: block;
}

.button-group--vertical {
    display: flex;
    flex-direction: column;

    // TODO: ideally Button should has block behaviour by default
    // till we have it we just fix stretching of buttons withing vertical button-groups with css
    & > .button-group__item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: stretch;
    }

    & > .button-group__item + .button-group__item {
        margin-top: var(--button-group-vertical-vgap);
    }
}

.button-group--horizontal {
    display: flex;
    flex-wrap: wrap;
    margin-right: calc(-1 * var(--button-group-horizontal-hgap));
    margin-bottom: calc(-1 * var(--button-group-horizontal-vgap));

    &:empty {
        margin: 0;
    }

    & > .button-group__item {
        margin-right: var(--button-group-horizontal-hgap);
        margin-bottom: var(--button-group-horizontal-vgap);
    }
}

.button-group--center {
    justify-content: center;
    width: 100%;

    &.button-group--vertical {
        display: grid;
        grid-template-columns: minmax(0, max-content);
    }
}
