// @import '_variables.scss';
// @import '_colors.scss';
// @import '_mixins.scss';
// @import '_functions.scss';

$token-placard-border-radius: 20px;
$token-placard-padding-vertical: 16px;
$token-placard-padding-horizontal: 20px;
$token-placard-spacing-media-content: 12px;
$token-placard-spacing-content-extra: 12px;
$token-placard-min-height: $token-ui-control-size-medium;

.placard {
    display: flex;
    align-items: center;
    width: 100%;
    min-height: $token-placard-min-height;
    padding: $token-placard-padding-vertical $token-placard-padding-horizontal;
    border-radius: $token-placard-border-radius;
    background-color: var(--placard-bg-color);
    color: var(--placard-color);
    text-align: left;
    transition: background-color 0.1s, color 0.1s;
}

.placard__media {
    flex: 0 0 auto;
    margin-right: $token-placard-spacing-media-content;
}

.placard__content {
    flex: 1 1 auto;
    min-width: 0;
}

.placard__extra {
    flex: 0 0 auto;
    margin-left: $token-placard-spacing-content-extra;
}

// ALIGN
.placard--align-top {
    .placard__media,
    .placard__extra {
        align-self: flex-start;
    }
}

// STATES
.placard {
    &.is-clickable:hover,
    &.is-hovered {
        background-color: var(--placard-bg-color-hover);
    }

    &.is-clickable:focus,
    &.is-clickable:active,
    &.is-pressed {
        background-color: var(--placard-bg-color-active);
    }

    &.is-clickable {
        cursor: pointer;
    }
}

// STYLING
.placard--cell {
    --placard-color: #{$token-color-black};
    --placard-bg-color: #fff;
    --placard-bg-color-hover: #{color-hover-light(#fff)};
    --placard-bg-color-active: #{color-hover-light(#fff)};
    --placard-border-color: #{$token-control-border-color};

    box-shadow: inset 0 0 0 1px var(--placard-border-color);

    &.is-clickable:hover,
    &.is-hovered {
        --placard-border-color: #{$token-control-border-color-hover};
    }

    &.is-clickable:active,
    &.is-pressed {
        --placard-border-color: #{$token-control-border-color-active};
    }
}

// COLOR
@each $color, $token in map-extract-keys($ui-color-map, 'mono', 'brand') {
    .placard--filled.#{$color} {
        --placard-bg-color: #{$token};
        --placard-bg-color-hover: #{color-hover($token)};
        --placard-bg-color-active: #{color-active($token)};
        --placard-color: #fff;
    }
}

.placard--filled.color-white.placard--filled {
    --placard-color: #{$token-color-black};
}

.placard--filled-gradient {
    background: $token-color-page-gradient;
}
