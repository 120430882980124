// @import '_variables.scss';

.page-header {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 0 0 auto;
    padding-top: 48px;
    padding-right: $token-ui-layout-horizontal-padding;
    padding-left: $token-ui-layout-horizontal-padding;
}

.mobile .page-header,
.tablet .page-header {
    padding-top: 16px;
    padding-right: $token-ui-layout-horizontal-padding;
    padding-left: $token-ui-layout-horizontal-padding;
}
