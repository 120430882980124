// @import '_colors.scss';
// @import '_variables.scss';

.hero-title {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.hero-title__image {
    top: 0;
    left: 0;
    width: 258px;
    margin-right: 12px;
    margin-bottom: -$token-spacing-xxlg;
}

.hero-title__text {
    font-size: 40px;
    font-style: normal;
    font-weight: 600;
    line-height: 40px;
    text-shadow:
        -1px -1px 0 $token-color-black,
        1px -1px 0 $token-color-black,
        -1px 1px 0 $token-color-black,
        1px 1px 0 $token-color-black,
        6.4px 3.84px 0px $token-color-black,
        5.6px 3.36px 0px $token-color-black,
        4.8px 2.88px 0px $token-color-black,
        4px 2.4px 0px $token-color-black,
        3.2px 1.92px 0px $token-color-black,
        2.4px 1.44px 0px $token-color-black,
        1.6px 0.96px 0px $token-color-black,
        0.8px 0.48px 0px $token-color-black;
    color: $token-color-white;
    display: flex;
    padding: $token-spacing-md;
    margin: 0;
    align-items: center;
    text-align: center;
    text-transform: uppercase;
}

@media (max-width: $token-breakpoint-mobile) {
    .hero-title__image {
        margin-bottom: -56px;
    }

    .hero-title__text {
        font-size: 50px;
        line-height: 50px;
    }
}