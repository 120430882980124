.header {
    --header-height: 48px;

    @include apply-screen-size(desktop-sm) {
        --header-height: 80px;
    }

    height: var(--header-height);
    background: var(--header-bg-color, #fff);
}

.header__inner {
    z-index: var(--header-z-index);
    display: flex;
    align-items: center;
    height: inherit;
    padding: 0 20px;
    background: inherit;
    transition: 0.2s transform;
    background-color: #FFFCF3;

    &.is-fixed {
        position: fixed;
        top: 0;
        right: 0;
        left: 0;
        background-color: #fff;

        &.is-transparent {
            background-color: #fff;
        }
    }

    &.is-hidden {
        transform: translate3d(0, -100%, 0);
    }

    &.has-shadow {
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    }

    .is-opened-mobile-nav & {
        z-index: calc(var(--mobile-panel-z-index) + 1);
    }

    &.is-transparent {
        background-color: transparent;
    }

    &.is-primary {
        --logo-color: var(--color-black);

        background-color: var(--color-primary);
    }

    @media screen and (min-width: 600px) {
        position: fixed;
        top: 0;
        right: 0;
        left: 0;
    }
}

.header__menu {
    display: none;
}

.header__actions {
    display: flex;
    align-items: center;

    // .header__inner.has-actions-v-aligned & {
    //     margin-top: 23px; // v-align hack
    // }
}

.header__logotype {
    display: flex;
    flex: 1 0 auto;
    align-self: center;
}

.header__logotype-link {
    --logo-width: 80px;

    display: inline-block;
    vertical-align: middle;
    text-decoration: none;
    cursor: pointer;

    .logotype {
        color: var(--color-primary);
    }
}

.header__actions-item {
    color: var(--header-button-color);

    .button {
        overflow: hidden;
    }
}

.header__actions-item--mobile-menu {
    .button {
        padding-right: 0 !important;

        @media screen and (min-width: 800px) {
            padding-right: 20px !important;
        }
    }

    .icon {
        color: var(--color-primary);
    }
}

.header__actions-item--dw-buttons {
    display: none;
}

.header__actions-item--download-ios {
    display: none;

    .ios & {
        display: block;
    }
}

.header__actions-item--download-android {
    display: none;

    .android & {
        display: block;
    }
}

.header__actions-item + .header__actions-item {
    margin-left: 60px;
}

.header__logotype + .header__actions {
    margin-left: auto;
}

@media screen and (min-width: 800px) {
    .header__inner {
        padding-right: 60px;
        padding-left: 62px;
    }

    .header__logotype {
        flex: 0 0 auto;
    }

    .header__logotype-link {
        --logo-width: 160px;
    }
}
