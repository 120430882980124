// @import '_variables.scss';
// @import '_colors.scss';
// @import '_mixins.scss';
// @import '_functions.scss';

.button {
    --button-size: #{$token-ui-button-size-medium};
    --button-horizontal-padding: #{$token-ui-button-horizontal-padding-medium};
    --button-bg-color: transparent;
    --button-bg-color-hover: var(--button-bg-color, transparent);
    --button-bg-color-active: var(--button-bg-color-hover, transparent);
    --button-text-color: #{$token-color-white};
    --button-text-color-hover: var(--button-text-color, #{$token-color-white});
    --button-text-color-active: var(--button-text-color-hover, #{$token-color-white});

    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: top;
    min-width: var(--button-size);
    max-width: 100%;
    width: 100%;
    min-height: var(--button-size);
    height: var(--button-size);
    padding: 5px var(--button-horizontal-padding); // the vertical padding is for the rare case the text is multi-line
    border-radius: calc(var(--button-size, #{$token-ui-button-size-medium}) / 2);
    background-color: var(--button-bg-color);
    text-align: center;
    text-decoration: none;
    transition: background 0.1s ease-in-out;
    cursor: pointer;
    user-select: none;

    // TODO: remove it
    &::before {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        content: '';
        border-radius: inherit;
        opacity: 0;
        transition: opacity 0.2s;
    }
}

// TODO: remove. extra wrapper
.button__content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1 1 auto;
    color: var(--button-text-color);
}

.button__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 0 0 auto;
}

.button__text {
    display: block;
    flex: 1 1 auto;
    white-space: nowrap;
    transition: color 0.2s;
}

.button__icon + .button__text {
    margin-left: 10px;
}

.button__loader {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: inherit;
    height: inherit;
    margin: auto;
    color: var(--button-text-color);
}

// SIZES
.button--size-m {
    --button-size: #{$token-ui-button-size-medium};
    --button-horizontal-padding: #{$token-ui-button-horizontal-padding-medium};
}


// OPTIONS
.button--narrow {
    display: inline-flex;
    width: auto;
}

// STATES
.button {
    &:hover,
    &.is-hovered {
        background-color: var(--button-bg-color-hover);
        text-decoration: none;

        .button__content,
        .button__loader {
            color: var(--button-text-color-hover);
        }
    }

    &:active,
    &.is-pressed {
        background-color: var(--button-bg-color-active);

        .button__content,
        .button__loader {
            color: var(--button-text-color-active);
        }
    }

    &:disabled,
    &.is-disabled {
        opacity: 0.5;
        cursor: default;
        pointer-events: none;

        &.is-fake-disabled {
            pointer-events: auto;
        }
    }

    &.is-loading {
        pointer-events: none;

        .button__content {
            opacity: 0;
        }

        .button__loader {
            display: block;
        }
    }
}

// FILL
.button--transparent {
    --button-bg-color: transparent;
    --button-bg-color-hover: #{color-hover-light(transparent)};
    --button-bg-color-active: #{color-active-light(transparent)};
}

// button that looks like a text-field. Bueehhh (((
// please confront designers when they use it
.button--cell {
    --button-text-color: #{$token-color-black};
    --button-text-color-hover: var(--button-text-color);
    --button-text-color-active: var(--button-text-color);
    --button-bg-color: #fff;
    --button-bg-color-hover: #{color-hover-light(#fff)};
    --button-bg-color-active: #{color-hover-light(#fff)};
    --button-border-color: #{$token-control-border-color};

    box-shadow: inset 0 0 0 1px var(--button-border-color);

    &:hover,
    &.is-hovered {
        --button-border-color: #{$token-control-border-color-hover};
    }

    &:active,
    &.is-pressed {
        --button-border-color: #{$token-control-border-color-active};
    }
}

.button--action {
    .button__content {
        flex-direction: row-reverse;
    }

    .button__text {
        margin-right: 10px;
        margin-left: 0;
        text-align: left;
    }

    .button__icon {
        color: var(--button-border-color);
    }
}

// COLOR
@each $color, $token in map-extract-keys($ui-color-map, 'brand', 'brand-shades', 'providers', 'generic', 'features', 'mono', 'others') {
    .#{$color} {
        &.button--filled {
            --button-bg-color: #{$token};
            --button-bg-color-hover: #{color-hover($token)};
            --button-bg-color-active: #{color-active($token)};
            --button-text-color: #fff;
        }

        &.button--transparent {
            --button-text-color: #{$token};
        }
    }
}


.button--color-primary {
    .button__text {
        color:rgb(29, 37, 44);
    }

    &:hover {
        background-color: var(--color-primary);
        opacity: 0.85;
    }
}

.button--footer-cta {
    display: inline-block;
    width: auto;
}

.color-provider-instagram.button--filled {
    background-image: $token-color-provider-instagram-gradient;

    &:hover,
    &.is-hovered {
        background-image: $token-color-provider-instagram-gradient-hover;
    }

    &:active,
    &.is-pressed {
        background-image: $token-color-provider-instagram-gradient-active;
    }
}

.button.color-white.button--filled,
.button.color-gray-light.button--filled {
    --button-text-color: #{$token-color-black};
}

.button.color-primary-light.button--filled {
    --button-text-color: #{$token-color-primary-contrast};
}
