// slider on highlight background
#highlights .content {
    padding: 100px 0;
    overflow: hidden;
}

#highlights h1 {
    margin-bottom: 52px;
}

.highlight-slide {
    width: 100%;
    padding: 0 10vw;
    max-width: 1400px;
    margin-left: auto;
    margin-right: auto;
    box-sizing: content-box;
}

.spotlight-img {
    max-width: 480px;
}

.spotlight-img img {
    width: 100%;
    border-radius: 24px;
}

.highlight-about {
    padding: 32px 0px 24px 80px;
}

.highlight-m-header {
    margin-bottom: 24px;
}

.highlight-name {
    gap: 16px;
}

.highlight-q {
    margin-bottom: 16px;
}

.highlight-a {
    padding-left: 24px;
    position: relative;
    max-width: 600px;
}

.highlight-a:after {
    content: '';
    height: 100%;
    width: 3px;
    background-color: var(--color-pollen);
    left: 0;
    top: 2px;
    position: absolute;
    border-radius: 1px;
}

@media only screen and (max-width: 900px) {
    #highlights .content {
        padding: 8vw 0;
    }

    #highlights h1 {
        margin-bottom: -12px;
    }

    .highlight-slide {
        padding: 0 24px;
    }

    .highlight-about {
        padding: 32px 0px 24px 0px;
    }

    .highlight-m-header {
        display: flex;
        align-items: flex-end;
        gap: 16px;
    }

    .highlight-m-header .highlight-name {
        flex-direction: column;
        gap: 0;
    }

    .highlight-m-header .highlight-name > h2 {
        font-size: 25px;
    }

    .highlight-m-header .highlight-name > * {
        margin: 0;
    }

    .highlight-q {
        padding-right: 36px;
    }
    .highlight-a {
        padding-left: 12px;
        padding-right: 36px;
    }

    .highlight-a:after {
        width: 1px;
    }
}

.glide--with-text {
    .post-description {
        width: 100%;
    }

    .glide__controls {
        margin: 48px 0;
    }
}