.hero-block {
    --aspect-ratio: 864 / 1320;

    position: relative;
    display: flex;
    max-width: 100%;
    width: 100vw;
    padding-top: calc(var(--aspect-ratio) * 100%);
    max-height: calc(100vh - var(--header-height));
    overflow: hidden;
}

.hero-block--full-width {
    max-height: none;

    @include apply-screen-size(tablet-md) {
        height: calc(100vh - var(--header-height));
    }
}

.hero-block__inner {
    --hero-block-vertical-padding: 20px;
    --hero-block-horizontal-padding: 48px;
    --text-color: #fffcf3;

    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 0);
    display: flex;
    width: 100%;
    height: 100%;
    padding: var(--hero-block-horizontal-padding) var(--hero-block-vertical-padding);
    max-width: 960px;
    margin: 0 auto;

    & > .h3 {
        padding-left: 0.2em;
    }

    & > .h1 {
        line-height: 0.8;
    }

    & > .h1 + .h4 {
        text-align: right;
        font-style: italic;
    }

    @include apply-screen-size(tablet-md) {
        --hero-block-vertical-padding: 60px;
        --hero-block-horizontal-padding: 88px;
    }
}

.hero-block__inner--center {
    justify-content: center;
    align-content: center;
    flex-wrap: wrap;
    flex-direction: column;
}

.hero-block__media-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    max-height: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

.hero-block__video-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

.hero-block__title {
    --hero-block-title-max-width: 654px;
    --hero-block-title-vertical-padding: 16px;
    --hero-block-title-horizontal-padding: 24px;
    --hero-block-border-radius: 8px;
    --hero-block-border-color: var(--color-base);
    --hero-block-bg-color: #fff;
    --hero-block-text-align: center;

    max-width: var(--hero-block-title-max-width);
    padding: var(--hero-block-title-horizontal-padding) var(--hero-block-title-vertical-padding);
    border-radius: var(--hero-block-border-radius);
    border: 1px solid var(--hero-block-border-color);
    background-color: var(--hero-block-bg-color);
    text-align: var(--hero-block-text-align);
    // @TODO - make it adjustable
    align-self: self-end;

    .h1 {
        --font-size: 32px;
        --line-height: 32px;
        // typography
        // 4% 56px
        letter-spacing: -2.24px;

        @include apply-screen-size(tablet-md) {
            --font-size: 56px;
            --line-height: 56px;
        }
    }

    @include apply-screen-size(tablet-md) {
        --hero-block-title-vertical-padding: 32px;
        --hero-block-title-horizontal-padding: 40px;
        --hero-block-border-radius: 16px;
        --hero-block-text-align: left;
    }
}

.hero-block__title--ambassadors {
    --text-color: #FFC629;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-style: italic;
    margin: auto;

    .h1 {
        @include apply-screen-size(desktop-md) {
            --font-size: 100px;
            --line-height: 1;
        }
    }
}

.hero-block--fullscreen {
    height: 100vh;
}

.hero-block--fluid {
    height: auto;
}

.hero-block--vertical {
    flex-direction: column;
}

.hero-block--primary {
    --color-primary: var(--theme-color-dating);

    background-color: var(--color-primary);
}

.hero-block--extra-margin {
    @include apply-screen-size(tablet-md) {
        margin-bottom: 128px;
    }
}

.hero-block--has-frame {
    &.hero-block--bff {
        --color-primary: #ffde3b;
        --aspect-ratio: 0;
        min-height: 50vh;

        @include apply-screen-size(tablet-md) {
            min-height: 0;
        }

        .hero-image__media > span::before,
        .hero-image__media > span::after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            transform: scale(1.0025, 1.0025);
            clip-path: url(#bff-frame-path);
        }

        .hero-image__media > span::before {
            background-color: #444647;
        }

        .hero-image__media > span::after {
            background: linear-gradient(0deg, rgba(29, 37, 44, 0.2), rgba(29, 37, 44, 0.2));
        }

        .hero-block__media-bg img {
            clip-path: url(#bff-frame-path);
        }

        .hero-image {
            &::before {
                content: none;
            }

            height: 100%;
        }

        .h1 {
            @include apply-screen-size(tablet-md) {
                transform: scale(0.6);
            }
        }
    }
}

.hero-block__iframe-container {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    min-width: 100%;
    min-height: 100%;
    z-index: 0;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    min-width: max(100vw, calc(100vh * 16 / 9));
    min-height: calc(118vw * 16 / 9);
}

.hero-block__iframe {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    min-width: 100%;
    min-height: 100%;
}
