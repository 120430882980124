// @import '_variables.scss';
// @import '_mixins.scss';

$button-group-vertical-vgap: 8px;
$button-group-horizontal-vgap: 8px;
$button-group-horizontal-hgap: 8px;

.footer-links {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.footer-links__item {
    .footer-links__link {
        color: #333;
        white-space: nowrap;
        transition: .2s color cubic-bezier(.75,.25,.25,.75);
        cursor: pointer;

        &:hover {
            color: $token-color-primary;
        }
    }

    .link {
        --link-color: #{$token-color-gray-dark};
        --link-border-color: #{rgba($token-color-gray-dark, 0.5)};
    }
}
.mobile .footer-links__item .footer-links__link:hover {
    color: initial;
}

.footer-links__item + .footer-links__item {
    margin-left: 16px;
}
