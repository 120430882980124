.page__article-content {
    .list,
    .h2,
    .h3,
    .schema-faq__question-answer-faq,
    .paragraph {
        max-width: 872px;
        padding: 0 20px;
        margin: 0 auto;
    }

    .list-item__inner {
        padding-left: 16px;
        font-weight: 400;
        font-size: 20px;
        letter-spacing: 0;
        line-height: 1.4;
        margin-top: 2px;
        margin-bottom: 2px;
        font-family: var(--font-family-book);
        -webkit-font-smoothing: antialiased;

        @media only screen and (max-width: 900px) {
            font-size: 18px;
        }
    }

    .list-item__inner::before {
        content: "\2022";
        color: #ffc629;
        font-weight: bold;
        display: inline-block;
        width: 1em;
        margin-left: -1em;
    }

    .list-item__inner + .list-item__inner,
    .paragraph + .paragraph {
        margin-top: 24px;
    }

    .paragraph + .h2,
    .paragraph + .h3,
    .paragraph + .h4,
    .hero-block + .paragraph {
        --section-vertical-gap: 48px;
        margin-top: var(--section-vertical-gap);

        @include apply-screen-size(tablet-md) {
            --section-vertical-gap: 72px;
        }
    }

    .list + .paragraph,
    script + .paragraph,
    .paragraph + .list,
    .h2 + .paragraph,
    .h3 + .paragraph,
    .h4 + .paragraph, {
        --section-vertical-gap: 24px;
        margin-top: var(--section-vertical-gap);

        @include apply-screen-size(tablet-md) {
            --section-vertical-gap: 36px;
        }
    }

    .h2 + .h3,
    .h3 + .h4,
    .h4 + .paragraph {
        --section-vertical-gap: 24px;
        margin-top: var(--section-vertical-gap);

        @include apply-screen-size(tablet-md) {
            --section-vertical-gap: 48px;
        }
    }
}