.js-lexem-highlighter {
    * {
        pointer-events: none;
    }

    .js-lexem {
        background: #bada55;
        pointer-events: auto;
        cursor: crosshair;

        &.js-lexem--server {
            background: #da7655;
        }
    }

    .scroll__inner,
    .paywall {
        pointer-events: all;
    }
}
