@import '_typography.scss';

// HEADING TEXT
.header-1 {
    @include header-1();
}

.header-2 {
    @include header-2();
}

// NORMAL TEXT
.p-1 {
    @include p-1();

    & p {
        @include p-1();
    }
}

.p-2 {
    @include p-2();

    & p {
        @include p-2();
    }
}

.p-3 {
    @include p-3();

    p {
        @include p-3();
    }
}

.action {
    @include action();
}

// WEIGHT
.font-weight-regular {
    @include font-weight('system', regular);
}

.font-weight-book {
    @include font-weight($token-font-family-custom, book); // it is 500
}

.font-weight-medium {
    @include font-weight($token-font-family-custom, medium); // it is 600
}

// ALIGN
.text-align-left { text-align: left; }
.text-align-center { text-align: center; }
.text-align-right { text-align: right; }
.text-align-inherited { text-align: inherit; }


// ELLIPSIS
.text-ellipsis {
    overflow: hidden;
    max-width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
}

// BREAK-WORDS
.text-break-words {
    word-wrap: break-word;
}

// COLOR
@each $color, $token in map-extract-keys($ui-color-map, 'brand', 'brand-shades', 'features', 'providers', 'generic', 'mono', 'others') {
    .text-#{$color} {
        color: $token;
    }
}

