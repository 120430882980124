html,
body {
  background-color: #1d252c;
}

.Header,
.Mobile,
.Intro,
.Footer {
  display: none !important;
}
.Main-content {
  padding: 0 !important;
}
.Site {
  max-width: 100% !important;
}
* > * {
  box-sizing: border-box;
  font-family: Circular20WebCyr-Bold, 'Europa', sans-serif;
}
.sqs-layout > .sqs-row {
  margin-left: 0 !important;
  margin-right: 0 !important;
}
[class*='sqs-col'] .sqs-block {
  padding: 0 !important;
  margin: 0 !important;
  width: 100% !important;
}
.Site .sqs-layout .sqs-row .sqs-block {
  padding: 0px !important;
}
html.async-hide {
  opacity: 1 !important;
}

.sqs-block-code {
  text-align: unset;
}
