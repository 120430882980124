.button {
    @include apply-typography-button-normal();

    position: relative;
    display: block;
    overflow: hidden;
    min-width: var(--button-min-width, 150px);
    height: var(--button-size-base, 44px);
    margin: 0;
    padding: 0 32px;
    outline: 0;
    border: none;
    border-radius: 100px;
    background: none;
    color: var(--button-color);
    text-decoration: none;
    // fix for hover
    transform: translateZ(0);
    transition: 0.2s ease opacity;
    cursor: pointer;
    user-select: none;
    -webkit-font-smoothing: antialiased;

    &::before {
        position: absolute;
        top: 0;
        left: 0;
        overflow: hidden;
        width: 100%;
        height: 100%;
        content: '';
        border-radius: 100px;
        background-color: transparent;
        transform: translate3d(-100%, 0, 0);
        transform-origin: left top;
        transition: transform 200ms ease-in-out;
    }
}

.button__content {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.button__text {
    display: block;
    max-width: 100%;
    white-space: nowrap;

    > span {
        display: block;
    }
}

.button__icon {
    display: block;
}

.button__icon + .button__text {
    margin-left: 8px;
}

.button--circle {
    min-width: var(--button-size-base);
    width: var(--button-size-base);
    padding-right: 0;
    padding-left: 0;

    &.button--stroke {
        width: 48px;
        height: 48px;
        border: 1px solid currentColor;
        border-radius: 50%;
        color: currentColor;
    }
}

.button--primary {
    box-sizing: border-box;
    border: 2px var(--color-primary) solid;

    &::before {
        background-color: var(--color-primary);
    }

    &.button--stroke {
        .button__content {
            position: absolute;
            right: 32px;
            left: 32px;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
        }

        &:hover {
            color: var(--color-base);

            &::before {
                background-color: var(--color-primary);
            }
        }

        // @todo later - introduce state for such case
        .page__section--secondary & {
            color: #fff;

            &:hover {
                color: var(--color-base);
            }
        }
    }

    &.button--filled {
        border: none;
        background-color: var(--color-primary);

        &::before {
            content: none;
        }
    }

    &.button--transparent {
        border: none;
        color: var(--color-primary);
        font-weight: 400;
        font-size: 1.15em;

        &::before {
            content: none;
        }
    }

    &:hover {
        &::before {
            transform: translate3d(0, 0, 0);
        }
    }

    &.button--color-primary-stroke {
        --color-primary: #ffde3b;
        border: 1px solid #444647;
    
        .button__text {
            color: #444647;
        }
    }
}

.button--white {
    border: 1px solid var(--button-white-border);

    &.button--filled {
        border: none;
        background: #fff;
        color: #282828;
    }

    &.button--stroke {
        border: 1px solid #fff;
        color: #fff;
    }
}

.button--color-dark {
    background-color: #282828;
    color: #fff;

    &::before {
        background-color: currentColor;
    }
}

.button--sm {
    --button-font-size: 10px;
    --button-size-base: 22px;
    --button-min-width: 75px;

    @include apply-screen-size(desktop-sm) {
        --button-font-size: 15px;
        --button-size-base: 44px;
        --button-min-width: 150px;
    }
}

.button--lg {
    @include apply-screen-size(desktop-md) {
        --button-font-size: 20px;
        --button-size-base: 88px;
        --button-min-width: 300px;
    }
}

.button--xlg {
    font-size: 20px;
    line-height: 24px;
}

.button--bold {
    font-family: var(--font-family-bold, "Circular20WebCyr-Bold");
}

.button--lg-text {
    --button-font-size: 20px;
}

// some specific cases
.mobile-panel__close .button {
    min-width: 0;
    padding-right: 0;

    .icon {
        color: var(--color-primary);
    }
}

.button--no-padding {
    overflow: visible;
    min-width: 0;
    width: auto;
    height: auto;
    padding: 0;
}

.button--menu-icon .icon {
    display: flex;
    width: 18px;
    height: 18px;
}

.button--dw-action {
    @include apply-brand-link-hover-state();

    overflow: visible;
    min-width: 0;
    height: 24px;
    padding: 0;
    color: var(--header-button-color);
    opacity: 1;
    font-size: 20px;
    line-height: 24px;
    transition: 200ms transform ease-in-out;

    &.button--cta {
        border: 2px solid var(--button-cta-border-color);
        padding: 5px 20px;
        min-height: 40px;
    }

    &::before {
        content: none;
    }

    .button__text {
        font-family: var(--font-family-bold);
        font-weight: var(--font-weight-bold);
        color: var(--header-button-color);
    }
}

.button--inline {
    max-width: 100%;
    width: min-content;
}

.button--fixed-width {
    --button-min-width: 240px;

    @include apply-screen-size(desktop-md) {
        --button-min-width: 300px;
    }
}

.button--cta-read-more {
    background-color: #ffc629 !important;
    border: 2px solid #1d252c;
    border-radius: 32px;

    &:hover {
        background-color: #1d252c !important;
    }

    .button__content {
        color: #1d252c;
    }

    .button__text {
        font-weight: 500;
        font-family: var(--font-family-bold);
        text-transform: uppercase;
    }

    &.button--cta-read-more-contrast {
        background-color: #fff !important;

        &:hover {
            background-color: #1d252c !important;
        }
    }

    .page-section--secondary & {
        &:hover {
            background-color: #ffc629 !important;

            .button__content {
                color: #1d252c;
            }
        }
    }
}

.button--cta-blog {
    background-color: transparent !important;
    border: 1px solid #1d252c;
    border-radius: 32px;

    &:hover {
        background-color: #1d252c !important;
    }

    .button__content {
        color: #1d252c;
    }

    .button__text {
        font-weight: 300;
        font-family: var(--font-family-bold);
        text-transform: uppercase;
    }

    &.button--cta-read-more-contrast {
        background-color: #fff !important;

        &:hover {
            background-color: #1d252c !important;
        }
    }

    .page-section--secondary & {
        &:hover {
            background-color: #ffc629 !important;

            .button__content {
                color: #1d252c;
            }
        }
    }
}

.button--cta-primary {
    background-color: transparent !important;
    border: 2px solid #ffc629;

    .button__content {
        color: #ffc629;
    }
}
