@import '_variables.scss';
@import '_helpers.scss';
@import '_typography.scss';

*,
*::before,
*::after {
    position: relative;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

// 1. Correct the line height in all browsers.
// 2. Prevent adjustments of font size after orientation changes in IE on Windows Phone and in iOS.
// 3. Setting @viewport causes scrollbars to overlap content in IE11 and Edge, so we force a non-overlapping, non-auto-hiding scrollbar to counteract.

html {
    font-size: 100%;
    line-height: 1.15; /* 1 */
    text-size-adjust: 100%; /* 2 */
    -ms-overflow-style: scrollbar; /* 3 */
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

main,
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
    display: block;
}

body {
    text-rendering: auto;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
}

// Show the overflow in Edge
button,
input {
    overflow: visible;
}

/**
 * 1. Prevent a WebKit bug where (2) destroys native `audio` and `video`
 *    controls in Android 4.
 * 2. Correct the inability to style clickable types in iOS and Safari.
 */

button,
html [type='button'], /* 1 */
[type='reset'],
[type='submit'] {
    appearance: button; /* 2 */
}

// Remove the inheritance of text transform in Edge, Firefox, and IE.
button,
select {
    text-transform: none;
}

/**
 * Remove the inner border and padding in Firefox.
 */

button::-moz-focus-inner,
[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner {
    padding: 0;
    border-style: none;
}

/**
 * Restore the focus styles unset by the previous rule.
 */

button:-moz-focusring,
[type='button']:-moz-focusring,
[type='reset']:-moz-focusring,
[type='submit']:-moz-focusring {
    outline: 1px dotted ButtonText;
}

/**
 * Correct the cursor style of increment and decrement buttons in Chrome.
 */

[type='number']::-webkit-inner-spin-button,
[type='number']::-webkit-outer-spin-button {
    height: auto;
}

/**
 * 1. Correct the odd appearance in Chrome and Safari.
 * 2. Correct the outline style in Safari.
 */

[type='search'] {
    outline-offset: -2px; /* 2 */
    appearance: textfield; /* 1 */
}

/**
 * Remove the inner padding and cancel buttons in Chrome and Safari on macOS.
 */

[type='search']::-webkit-search-cancel-button,
[type='search']::-webkit-search-decoration {
    appearance: none;
}

/**
 * 1. Correct the inability to style clickable types in iOS and Safari.
 * 2. Change font properties to `inherit` in Safari.
 */

::-webkit-file-upload-button {
    font: inherit; /* 2 */
    appearance: button; /* 1 */
}

input,
button,
select,
optgroup,
textarea {
    margin: 0; // Remove the margin in Firefox and Safari
    border: 0;
    background-color: transparent;
    font-size: inherit;
    line-height: inherit;
    font-family: inherit;
    appearance: none; // remove webkit appearance
}

select {
    background: transparent;
}

ol,
ul {
    list-style: none;
}

iframe {
    vertical-align: top;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

b,
strong {
    // we can have this assumption as long as base font-family is custom
    @include font-weight($token-font-family-custom, medium);

    color: inherit;
}

a {
    text-decoration-skip: objects;
    text-decoration: none;
}

p {
    margin-top: 0;
    margin-bottom: 0;
}

img {
    bottom: 0;
}

// Remove the default outline
:focus {
    outline: none;
}

// IE11: Hide the overflow
svg:not(:root) {
    overflow: hidden;
}

// Always hide an element when it has the `hidden` HTML attribute.
[hidden] {
    display: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    @include font-weight($token-font-family-custom, book);

    margin: 0;
    font-size: inherit;
}

blockquote {
    color: $token-color-black;
    font-size: 16px;

    &::before {
        content: '\201C';
    }

    &::after {
        content: '\201D';
    }
}

cite {
    font-size: 16px;
}

label[for] {
    cursor: pointer;
}

// hack till font colors is fixed on server side
// _views/Contacts/Color.php
font[color] {
    color: $token-color-primary !important;
}
