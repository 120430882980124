@use 'sass:math';

@import '_functions.scss';
@import '_colors.scss';

// Small typography. It's better to use typography tokens though
$ui-font-size-base: 16px;
$ui-font-size-secondary: 14px;
$ui-font-size-tetriary: 12px;

//Z-indexes
$z-blocker: 2;
$z-chat-dialog: 10;
$z-in-app-notifications: 10;
$z-page-sidebar: 20;
$z-page-content: 10;
$z-page-dialog: 30;
$z-video-call-incoming: 35;
$z-hide-cursor: 40;

// UI Sizes
$token-ui-step: 4px;

// Icon sizes
$ui-icon-size-xs: 12px;
$ui-icon-size-s: 16px;
$ui-icon-size-m: 24px;
$ui-icon-size-l: 32px;
$ui-icon-size-xl: 48px;
$ui-icon-size-xxl: 56px;
$ui-icon-size-jumbo-m: 96px;
$ui-icon-size-jumbo-l: 144px;

// Emoji sizes
$ui-emoji-size-xs: $ui-icon-size-xs;
$ui-emoji-size-s: $ui-icon-size-s;
$ui-emoji-size-m: $ui-icon-size-m;
$ui-emoji-size-l: $ui-icon-size-l;

/*
* We should think about:
* Height for all control elements. Values was taken from design files
* Actually we don't want to use this values as is.
* For example buttons: height should be produced from font-size/line-height and paddings.
* No need to set button height. In the end we will get more flexibility
* Harmonious sizes based on font-family
*/

$token-ui-control-size-medium: 40px;
$token-ui-control-horizontal-padding-medium: 20px; // horizontal gap between borders and text
$token-ui-control-vertical-padding-medium: 8px;
$token-ui-control-partial-border-radius-medium: 4px; // partial border radius

$token-ui-border-radius-size-small: 8px;
$token-ui-border-radius-size-medium: 16px;
$token-ui-border-radius-size-large: 24px;

// Layout
$token-ui-layout-horizontal-padding: 20px;
$token-ui-layout-vertical-padding: 20px;
$token-ui-cookie-notification-height: 40px;
$token-ui-layout-profile-width: 302px;
$token-ui-layout-content-max-width: 800px;
$token-ui-desktop-sidebar-width: 25vw;
$token-ui-desktop-sidebar-width-collapsed: 72px;
$token-ui-desktop-sidebar-width-expanded: 320px;
$token-ui-desktop-sidebar-width-max: 400px;
$token-ui-desktop-sidebar-width-min: 260px;
$token-ui-desktop-profile-width: 300px;
$token-ui-desktop-encounters-frame-min-width: 560px;
$token-ui-desktop-encounters-frame-max-width: 1172px;
$token-ui-desktop-encounters-frame-min-height: 373px;
$token-ui-desktop-header-height: 72px;
$token-ui-desktop-layout-minimum-width: 760px;
$token-ui-desktop-settings-content-width: 480px;
$token-ui-layout-border-color: $token-color-gray;
$token-ui-desktop-layout-horizontal-padding: 20px;

// Breakpoints
$token-breakpoint-mobile: 800px;
$token-breakpoint-desktop-narrow: math.div($token-ui-desktop-sidebar-width-min * 100vw, $token-ui-desktop-sidebar-width);
$token-breakpoint-desktop-wide: 1600px;

// Fields
$token-control-border-color: $token-color-gray;
$token-control-border-color-hover: color-shade($token-control-border-color, 10%);
$token-control-border-color-focused: var(--color-primary);
$token-control-border-color-active: color-shade($token-control-border-color, 20%);
$token-control-border-color-disabled: rgba($token-control-border-color, 0.5);
$token-control-border-color-error: $token-color-error;
$token-control-border-color-alert: var(--color-primary);
$token-control-background-color: #fff;
$token-control-background-color-hover: color-hover-light(#fff);
$token-control-background-color-active: $token-control-background-color-hover;
$token-control-background-color-disabled: rgba($token-control-background-color, 0.5);
$token-control-color: $token-color-black;
$token-control-color-disabled: rgba($token-control-color, 0.5);
$token-control-color-placeholder: $token-color-gray-dark;

// Settings-section
$token-ui-settings-section-header-padding: $token-ui-control-horizontal-padding-medium;
$token-ui-settings-section-header-right-padding: $token-ui-settings-section-header-padding * 2 + $ui-icon-size-m;

// Settings-fieldset
$token-ui-settings-fieldset-hint-horizontal-padding: $token-ui-control-horizontal-padding-medium;
$token-ui-settings-fieldset-title-horizontal-padding: $token-ui-control-horizontal-padding-medium;

// Buttons
$token-ui-button-size-medium: $token-ui-control-size-medium;
$token-ui-button-horizontal-padding-medium: $token-ui-control-horizontal-padding-medium;

// Text-field
$token-ui-text-field-size-medium: $token-ui-control-size-medium;
$token-ui-text-field-horizontal-padding-medium: $token-ui-control-horizontal-padding-medium;

// Select-field
$token-ui-select-size-medium: $token-ui-control-size-medium;
$token-ui-select-horizontal-padding-medium: $token-ui-control-horizontal-padding-medium;

// Search-field
$token-ui-search-field-size-medium: $token-ui-control-size-medium;
$token-ui-search-field-horizontal-padding-medium: $token-ui-control-horizontal-padding-medium;

// Textarea
$token-ui-textarea-horizontal-padding-medium: 20px;
$token-ui-textarea-vertical-padding-medium: 12px;
$token-ui-textarea-border-radius-medium: 20px;

// Choice-field
$token-ui-choice-field-size-medium: $token-ui-control-size-medium;
$token-ui-choice-field-horizontal-padding-medium: $token-ui-control-horizontal-padding-medium;

// Avatar
$token-ui-avatar-size-small: 48px;
$token-ui-avatar-size-medium: 96px;
$token-ui-avatar-size-large: 172px;
$ui-avatar-notification-size: 10px;

// Loaders
$ui-loader-dot-size-m: 6px;
$ui-loader-gap-size-m: 6px;
$ui-loader-logo-height-m: 32px;

$ui-loader-dot-size-s: 4px;
$ui-loader-gap-size-s: 2px;
$ui-loader-logo-height-s: 24px;

$ui-loader-dot-size-l: 14px;
$ui-loader-gap-size-l: 8px;
$ui-loader-logo-height-l: 64px;

// Links
$token-ui-link-color: $token-color-black;
$token-ui-link-color-hover: $token-color-black;

// Components placeholder
$token-placeholder-bg-color: $token-color-gray-light;
$token-placeholder-border-color: $token-color-gray-light;

// Messenger
$token-ui-desktop-chat-header-height: $token-ui-desktop-header-height;
$token-ui-desktop-chat-messages-field-height: 72px;
$token-ui-chat-contact-avatar-size: $token-ui-avatar-size-small;
$token-ui-chat-contact-vertical-padding: 12px;
$ui-chat-carousel-item-padding-vertical: 8px;
$ui-chat-carousel-item-padding-horizontal: 8px;
$ui-gif-uploader-width: 294px;
$ui-gif-uploader-height: 400px;

$ui-chat-message-bubble-in-bg: $token-color-primary;
$ui-chat-message-bubble-in-text: $token-color-black;
$ui-chat-message-bubble-out-bg: $token-color-gray-light;
$ui-chat-message-bubble-out-text: $token-color-black;

// Easing variables
$ui-easing-ease-out-quint: cubic-bezier(0.25, 0.1, 0.25, 1);

// CtaBox
$ui-cta-box-title-size: $token-font-size-header-1;
$ui-cta-box-title-line-height: $token-line-height-header-1;
$ui-cta-box-text-size: $token-font-size-paragraph-1;
$ui-cta-box-text-line-height: $token-line-height-paragraph-1;
$ui-cta-box-max-width: 560px;
$ui-cta-box-media-bottom-gap: 24px;
$ui-cta-box-text-top-gap: 12px;
$ui-cta-box-content-gap: 24px; // default gap for custom content. Actually can be anything by design
$ui-cta-box-actions-top-gap: 32px;
$ui-cta-box-hint-gap: 24px;

// Dialogs
$ui-dialog-base-padding: 32px;
$ui-dialog-media-bottom-gap: 24px;
$ui-dialog-description-top-gap: 12px;
$ui-dialog-content-top-gap: 24px;
$ui-dialog-actions-top-gap: 32px;
$ui-dialog-hint-gap: 24px;
$ui-dialog-buttons-width: 120px;
$ui-dialog-width-medium: 480px;
$ui-dialog-width-large: 600px;
$ui-dialog-width-xlarge: 744px;

// Sidebar
$token-ui-sidebar-icon-size: 24px;
$token-ui-sidebar-horizontal-padding: 16px;
$token-ui-sidebar-horizontal-padding-collapsed: 12px;
$token-ui-sidebar-avatar-size: 48px;

$ui-sidebar-extra-banner-padding: 8px;
$ui-sidebar-extra-banner-icon-size: 32px;
$ui-sidebar-extra-banner-icon-gap: 16px;
$ui-sidebar-extra-banner-border-radius: ($ui-sidebar-extra-banner-icon-size + 2 * $ui-sidebar-extra-banner-padding) * 0.5;

// Encounters
$token-ui-encounters-frame-horizontal-padding: 64px;
$token-ui-encounters-action-size: 80px;
$token-ui-encounters-action-size-sm: 40px;
$token-ui-encounters-action-size-lg: 96px;

$token-ui-registration-control-max-width: 390px;

// Checkboxes
$ui-checkbox-size-small: 16px;
$ui-checkbox-size-medium: 24px;

$ui-radio-size-small: 16px;
$ui-radio-size-medium: 24px;

$ui-input-choice-color-base-unselected: $token-color-gray;
$ui-input-choice-color-base-selected: var(--color-primary);
$ui-input-choice-color-base-unselected-hover: var(--color-primary);
$ui-input-choice-color-base-selected-hover: var(--color-primary-hover);


// Paywall
$ui-paywall-content-width: 800px;

// Gallery
$ui-gallery-preview-min-size: 400px;
$ui-gallery-preview-max-width: 1200px;
$ui-gallery-preview-max-height: 700px;

// Options
$token-ui-option-height: 32px;


$ui-button-group-item-gap-horizontal: 12px;
$ui-button-group-item-gap-vertical: 12px;
