// @import 'mixins.scss';

.hint--color-white {
    // cover cases when html came from server and can't be controlled
    a[href]:not(.link) {
        @include simpleLink($token-color-white, $token-color-white, true, true);
    }

    .link {
        --link-color: #fff;
        --link-color-hover: #fff;
        --link-border-color: rgba(255, 255, 255, 0);
    }
}

.hint--color-black {
    a[href]:not(.link) {
        @include simpleLink($token-color-black, $token-color-black, true, true);
    }

    .link {
        --link-color: $token-color-black;
        --link-color-hover: $token-color-black;
        --link-border-color: #{rgba($token-color-black, 0.3)};
    }
}

.hint--color-gray-dark {
    a[href]:not(.link) {
        @include simpleLink($token-color-gray-dark, $token-color-black, true, true);
    }

    .link {
        --link-color: $token-color-gray-dark;
        --link-color-hover: $token-color-black;
        --link-border-color: #{rgba($token-color-gray-dark, 0.3)};
    }
}
