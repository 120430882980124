// @import '_variables.scss';
// @import '_colors.scss';
// @import '_mixins.scss';

.icon {
    // display: block;
    // width: 24px;
    // height: 24px;
    fill: currentColor;
    // TODO: remove color transition (?). It should be inherited from a parent
    transition: color 0.2s, fill 0.2s, background 0.2s, box-shadow 0.2s, opacity 0.2s;
}

/* @rtl begin */
//.icon--reflect {
//    transform: scaleX(-1);
//}
/* @rtl end */

// Size
.icon--size-xs {
    @include size($ui-icon-size-xs);
}

.icon--size-s {
    @include size($ui-icon-size-s);
}

.icon--size-m {
    @include size($ui-icon-size-m);
}

.icon--size-l {
    @include size($ui-icon-size-l);
}

.icon--size-xl {
    @include size($ui-icon-size-xl);
}

.icon--size-xxl {
    @include size($ui-icon-size-xxl);
}

// jumbo sizing is not what we expect from icons.
// TODO: we need to review large assets behaviour and for 96 and 144px cases create separate components probably, where icons could stretch
.icon--size-jumbo-m {
    @include size($ui-icon-size-jumbo-m);
}

.icon--size-jumbo-l {
    @include size($ui-icon-size-jumbo-l);
}

.icon--size-stretch {
    @include size(100%);
}

// Basic colors
@each $color, $token in map-extract-keys($ui-color-map, 'brand', 'brand-shades', 'features', 'providers', 'mono', 'others', 'generic') {
    .icon.#{$color} {
        color: $token; // need it for Cosmos icons that have fill="currentColor"
    }
}
