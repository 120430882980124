.card {
    --card-radius: 16px;
    border-radius: var(--card-radius);
    overflow: hidden;
    display: flex;
    flex-direction: column;

    @include apply-screen-size(tablet-md) {
        --card-radius: 24px;
    }
}

.card__header {
    --aspect-ratio: 1;
    position: relative;
    flex: 0 1 auto;

    &::before {
        display: block;
        width: 100%;
        padding-top: calc(var(--aspect-ratio) * 100%);
        content: '';
    }
}

.card__content {
    background-color: #fffcf3;
    border-radius: 0 0 var(--card-radius) var(--card-radius);
    padding: 16px;
    flex: 1 1 auto;

    .category--spring & {
        background-color: #ffffff;
    }
}

.card__media {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: #ffc629;
}

.card__title + .card__description {
    margin-top: 8px;
}

.card__description + .card__cta {
    margin-top: 8px;
}
