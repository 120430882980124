// @import '_variables.scss';

.page-content {
    display: block;
    flex: 1 1 auto;
    padding: 0 $token-ui-layout-horizontal-padding;
}

.mobile .page-content {
    padding: 0 $token-spacing-lg;
}
