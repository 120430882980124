@import '../../node_modules/cosmos-tokens/bumble/tokens.scss';
@import '../../node_modules/cosmos-tokens/bumble/tokens.map.scss';

// Base colors map
$token-color-provider-instagram-gradient:
    linear-gradient(
        -226deg,
        #d0165f 0%,
        #e1475b 51%,
        #ee592c 100%
    );
$token-color-provider-instagram-gradient-hover:
    linear-gradient(
        -226deg,
        color-shade(#d0165f, 5%) 0%,
        color-shade(#e1475b, 5%) 51%,
        color-shade(#ee592c, 5%) 100%
    );
$token-color-provider-instagram-gradient-active:
    linear-gradient(
        -226deg,
        color-shade(#d0165f, 10%) 0%,
        color-shade(#e1475b, 10%) 51%,
        color-shade(#ee592c, 10%) 100%
    );

// Tokens, that miss in Cosmos by some reasons, but ideologically should be there
$token-color-provider-trustpilot: #eda543;
$token-color-feature-superswipe: $token-color-primary;

// Custom background gradient for page
$token-color-page-gradient:
    linear-gradient(180deg, #FFD95C 0%, #FFC72B 100%);


@function get-tokens-map-section ($section) {
    $section-colors: ();

    @each $color, $token in map-get($tokens-color-map, $section) {
        $section-colors:
            map-merge(
                $section-colors,
                (
                    'color-#{$color}': $token
                )
            );
    }

    @return $section-colors;
}

// $tokens-color-map can't be used directly from Cosmos, cause we have additional colors
// so we create intermediate tokens maps
// todo: reconsider data-structure approach comparing with src/components/enum/global
$ui-color-map: (
    brand: get-tokens-map-section('brand'),
    brand-shades: (
        color-primary-light: $token-color-primary-light,
    ),
    providers: map-merge(
        get-tokens-map-section('providers'),
        (
            color-provider-trustpilot: $token-color-provider-trustpilot
        )
    ),
    generic: get-tokens-map-section('generic'),
    features: map-merge(
        get-tokens-map-section('features'),
        (
            color-feature-superswipe: $token-color-feature-superswipe
        )
    ),
    others: get-tokens-map-section('others'),
    mono: get-tokens-map-section('mono'),
);

