// @import '_variables.scss';

.join-hives-page {
    .page-header {
        padding: $token-spacing-xxlg 0;
    }

    .page-footer__social-links {
        display: none;
    }

    .cta-box__text .p-1 {
        color: #141414;
    }
}

.join-hives-page.mobile {
    background: $token-color-page-gradient;
    padding-top: 4px;

    .page-header {
        padding: 20px 0;
    }

    .page-footer__social-links {
        display: flex;
    }

    .check-list {
        padding: 0 10px;
    }
}

.join-hives-desktop {
    margin: 0 auto;
    max-width: 800px;
}

.join-hives--desktop {
    display: flex;
    align-items: center;

    > div {
        padding: $token-spacing-lg;
        flex-basis: 50%;
        flex-grow: 1;
    }

    .hero-title {
        padding-top: 10px;
    }
}

.join-hives__left {
    text-align: center;
}

.join-hives__store-badges {
    margin-top: $token-spacing-lg;

    > a {
        margin-right: $token-spacing-sm;
    }
}