.highlights {
    .content {
        padding: 100px 0;
        overflow: hidden;

        @media only screen and (max-width: 900px) {
            padding: 8vw 0;
        }
    }

    .beehive-h1 {
        margin-bottom: 52px;

        @media only screen and (max-width: 900px) {
            margin-bottom: 18px;
        }
    }
}

.quote-container {
    padding: 16px 10vw;
    max-width: 950px;

    .glide & {
        box-sizing: content-box;
    }

    @media only screen and (max-width: 600px) {
        padding: 16px 24px;
    }
}

.quote {
    background-color: #ffedb4;
    position: relative;
    border-radius: 24px;
    padding: 40px 92px;

    @media only screen and (max-width: 600px) {
        padding: 24px 16px;
    }
}

.quotation {
    width: 40px;
    position: absolute;
    z-index: 2;

    &.left {
        top: 32px;
        left: 32px;

        @media only screen and (max-width: 600px) {
            top: -6px;
            left: -12px;
        }
    }

    &.right {
        bottom: 32px;
        right: 32px;

        @media only screen and (max-width: 600px) {
            bottom: -6px;
            right: -12px;
        }
    }
}

.author {
    margin-top: 16px;
    text-align: right;

    @media only screen and (max-width: 600px) {
        text-align: center;
    }
}

//
.glide {
    position: relative;
    width: 100%;
    box-sizing: border-box;
}
.glide * {
    box-sizing: inherit;
}
.glide__track {
    overflow: hidden;
}
.glide__slides {
    position: relative;
    width: 100%;
    list-style: none;
    backface-visibility: hidden;
    transform-style: preserve-3d;
    touch-action: pan-Y;
    overflow: hidden;
    padding: 0;
    white-space: nowrap;
    display: flex;
    flex-wrap: nowrap;
    will-change: transform;
}
.glide__slides--dragging {
    user-select: none;
}
.glide__slide {
    width: 100%;
    height: 100%;
    flex-shrink: 0;
    white-space: initial;
    user-select: none;
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: transparent;
}
.glide__slide a {
    user-select: none;
    -webkit-user-drag: none;
    -moz-user-select: none;
    -ms-user-select: none;
}
.glide__arrows {
    -webkit-touch-callout: none;
    user-select: none;
}
.glide__bullets {
    -webkit-touch-callout: none;
    user-select: none;
}
.glide--rtl {
    direction: rtl;
}
.wrapper {
    width: 100vw;
    max-width: 100vw;
}
.glide__slides {
    //   margin-bottom: ${this.getAttribute('bulletMargin') || 66}px;
}
.glide__slide {
    display: flex;
    flex-direction: column;
}
.glide__slide > * {
    max-width: 100%;
}
.post-image {
    max-width: 100%;
    width: 650px;
    min-width: 100%;
    height: 500px;
    max-height: 100vw;
    border-radius: 24px;
    transform: scale(0.995);
    box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0);
    transition: box-shadow 0.2s, transform 0.2s;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    // background-color: var(--color-yellow);
}
.post-imager:hover {
    transform: scale(1);
    box-shadow: 1px 1px 4px 1px rgba(0, 0, 0, 0.1);
}
.post-title {
    text-align: center;
    font-family: Circular20WebCyr-Bold;
    margin: 24px auto 0;
    line-height: 1em !important;
    width: 650px;
    max-width: 100%;
    padding-left: 0.01vw;
    padding-right: 0.01vw;
    padding-bottom: 0.1em;
}
.post-title-link {
    color: inherit;
    text-decoration: none;
}
.post-title-link + .post-description,
.post-title + .post-description {
    margin-top: 20px;
}
.post-description {
    width: 650px;
    margin-left: auto;
    margin-right: auto;
}
.glide__controls {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 32px;
}
glide__arrows {
    display: flex;
}
.glide__arrow {
    background: none;
    border: none;
    cursor: pointer;
}
.glide__bullets {
    padding-bottom: 3px;
    display: flex;
    justify-content: center;
}
.glide__bullet {
    width: 8px;
    height: 8px;
    background-color: #444647;
    border-radius: 50px;
    border: none;
    padding: 0;
    transition: width 0.2s, margin 0.2s;
    cursor: pointer;
}
.glide__bullet:focus {
    outline: none;
}
.glide__bullet:not(.glide__bullet--active) {
    margin: 0 20px;
}
.glide__bullet--active {
    background-color: var(--color-yellow);
    margin: 0 0;
    width: 48px;
}

@media only screen and (max-width: 600px) {
    .glide__slides {
        // margin-bottom: ${this.getAttribute('bulletMarginSm') || 8}px;
    }
    .glide__controls {
        transform: scale(0.6);
    }
}

// .glide__slide {
//     opacity: 0;
//     transition: 0 ease opacity 0.2s;

//     &.glide__slide--active {
//       transition: 0 ease opacity 0s;
//         opacity: 1;
//     }
// }