// @import '_variables.scss';
// @import '_mixins.scss';

.page-footer {
    display: block;
    flex: 0 0 auto;
    padding: 0 $token-ui-layout-horizontal-padding;
}

.page-footer__hint {
    max-width: 600px;
    margin: 0 auto;
}

.page-footer__links + .page-footer__hint {
    margin-top: 8px;
}

.page-footer__links {
    margin-top: $token-spacing-xlg;
}
.mobile .page-footer__links {
    margin-top: $token-spacing-xlg;
}

.page-footer__social-links {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: $token-spacing-md auto;
}

.page-footer__copyright {
    padding: $token-spacing-xlg 0;
    color: #bdbdbd;
    font-size: 12px;
    text-align: center;
}
.mobile .page-footer__copyright {
    color: $token-color-gray-dark;
}