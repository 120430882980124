.success-story__media {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    // background-color: var(--color-primary);
    border-radius: 24px;

    &:before {
        content: '';
        padding-bottom: calc( 320 / 460 * 100%);
    }

    .media-box__picture {
        background-color: transparent;
    }

    img {
        object-fit: contain;
    }
}

.success-story__image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 2rem;
}

.success-story__image--full {
    padding: 0;
    border-radius: 16px;
    overflow: hidden;
}

.success-story__image--with-border {
    border: 2px solid #444647;

    // fill the space for image with corners
    img {
        transform: scale(1.01);
    }
}
