// Native variables - used only for colors
// TODO:  review concept of native variables. Most of them can be moved to static SCSS variables - JS-17811
// Consider not using primary, primary-light, primary-contrast colors.
// Instead use $token-color-primary, $token-color-primary-light, $token-color-primary-contrast if the context is static
:root {
    --color-primary: #{$token-color-primary};
    --color-primary-hover: #{color-hover($token-color-primary)};
    --color-primary-active: #{color-active($token-color-primary)};
    --color-primary-light: #{$token-color-primary-light};
    --color-primary-contrast: #{$token-color-primary-contrast};
    --expiration-status-color: #{$token-color-primary};
}

.expiration-status-low {
    --expiration-status-color: #{$token-color-feature-expiring};
}

.expiration-status-average {
    --expiration-status-color: #{$token-color-primary};
}

.expiration-status-expired {
    --expiration-status-color: #{$token-color-feature-expired};
}

.expiration-status-extended {
    --expiration-status-color: #{$token-color-feature-extend};
}

