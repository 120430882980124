@keyframes ticker-animation {
    0% {
        transform: translate(-50%);
    }

    100% {
        transform: translateX(0%);
    }
}

.ticker {
    --animation-direction: normal;
    width: 100%;
    overflow: hidden;
    display: flex;
    align-items: center;
    background: var(--softyellow);
}

.ticker__track {
    display: grid;
    grid-template-columns: repeat(50, 1fr);
    transform: translate(-50%, 0);
    background-color: var(--color-black);
    animation: ticker-animation 90s infinite linear;
    animation-direction: var(--animation-direction);

    &.is-on-pause {
        animation-play-state: paused;
    }
}

.ticker__word {
    white-space: nowrap;
    text-transform: uppercase;
    font-size: 24px;
    line-height: 1.35em;
    font-weight: 900;
    font-style: italic;
    color: var(--color-yellow);
    margin: 14px 20px 12px;
}

.ticker--reverse {
    .ticker__track {
        animation-direction: reverse;
    }
}

.ticker--slow {
    .ticker__track {
        animation-duration: 45s;
    }
}