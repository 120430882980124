.header-menu {
    display: flex;
    align-items: center;
}

.header-menu__item {
    position: relative;

    &.has-dropdown {
        &::after {
            position: absolute;
            top: 100%;
            left: 0;
            display: block;
            width: 100%;
            height: 20px;
            content: '';
        }
    }
}

.header-menu__link {
    color: var(--color-base);
    white-space: nowrap;
    text-decoration: none;
    font-weight: var(--font-weight-medium);
    transition: .2s ease color;

    &:hover,
    &:active,
    .header-menu__item.is-active & {
        color: var(--color-primary);
    }
}

.header-menu__dropdown {
    position: absolute;
    top: 100%;
    left: -24px;
    overflow: hidden;
    padding: 16px 24px;
    background: var(--color-primary-light);
    opacity: 0;
    visibility: hidden;
    transform: translate3d(0, 0, 0);
    transition: .2s ease transform, .2s ease opacity, .2s ease visibility;

    .header-menu__item:hover & {
        opacity: 1;
        visibility: visible;
        transform: translate3d(0, 15px, 0);
    }
}

.header-menu__dropdown-item {
    display: block;
}

.header-menu__dropdown-link {
    color: var(--color-primary-accent);
    opacity: .8;
    white-space: nowrap;
    text-decoration: none;
    transition: .2s ease opacity;

    &:hover {
        opacity: 1;
    }
}

.header-menu__item + .header-menu__item {
    margin-left: 32px;
}

.header-menu__dropdown-item + .header-menu__dropdown-item {
    margin-top: 8px;
}
