.success-stories {
    --success-card-gap: 48px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;

    @include apply-screen-size(desktop-md) {
        --success-card-gap: 72px;
    }
}

.success-stories__item + .success-stories__item {
    margin-top: var(--success-card-gap);
}
