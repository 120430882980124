// @import '_colors.scss';

.social-links {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 200px;
}

.social-links__link {
    display: inline-block;
    color: #333;
    transition: .2s color cubic-bezier(.75,.25,.25,.75);
    width: 24px;
    height: 24px;

    &:hover {
        color: $token-color-primary;
    }

    > svg {
        width: 24px
    }
}