// @import '_variables.scss';

.styleguide-icon-list {
    display: flex;
    flex-wrap: wrap;
    margin: -5px;

    & + & {
        margin-top: 40px;
    }
}

.styleguide-icon-list__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 165px;
    margin: 5px;
    padding: 10px;
    background: #fafafa;
    text-align: center;
}

.styleguide-icon-list__icon {
    display: flex;
    align-items: center;
    flex: 1 0 auto;
    min-height: 44px;
}

.styleguide-icon-list__item-title {
    position: relative;
    flex: 0 0 auto;
    margin-top: 10px;
    color: $token-color-gray-dark;
    font-size: 10px;
    line-height: 1.2;
    user-select: all;
}

.styleguide-icon-list__canvas {
    flex: 0 0 auto;
    outline: 1px dotted rgba($token-color-black, 0.1);
    color: $token-color-black;
}
