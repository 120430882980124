html,
body,
main {
    height: 100%;
    margin: 0;
    background-color: #FEFBED;
    -webkit-font-smoothing: antialiased;
    scroll-behavior: smooth;
}

:root {
    --color-white: #fff;
    --color-smoke: #d8d8d8;
    --color-gray: #9b9b9b;
    --color-boulder: #5a5a5a;
    --color-black: #1d252c;
    --color-yellow: #ffc629;
    --color-lemon: #fdda64;
    --color-pollen: #fde780;
    --color-banana: #f3efa1;
    --color-cream: #fffaeb;
    --color-salmon: #f47c51;
    --color-navy: #064469;
}

.boulder {
    color: var(--color-boulder);
}

.black {
    color: var(--color-black);
}

.gray {
    color: var(--color-gray);
}

.smoke {
    color: var(--color-smoke);
}

.navy {
    color: var(--color-navy);
}

.yellow {
    color: var(--color-yellow);
}

.banana {
    color: var(--color-banana);
}

.lemon {
    color: var(--color-lemon);
}

.pollen {
    color: var(--color-pollen);
}

.salmon {
    color: var(--color-salmon);
}

.cream {
    color: var(--color-cream);
}

.white {
    color: var(--color-white);
}

.boulder-bg {
    background-color: var(--color-boulder);
}

.black-bg {
    background-color: var(--color-black);
}

.gray-bg {
    background-color: var(--color-gray);
}

.smoke-bg {
    background-color: var(--color-smoke);
}

.navy-bg {
    background-color: var(--color-navy);
}

.yellow-bg {
    background-color: var(--color-yellow);
}

.banana-bg {
    background-color: var(--color-banana);
}

.lemon-bg {
    background-color: var(--color-lemon);
}

.pollen-bg {
    background-color: var(--color-pollen);
}

.salmon-bg {
    background-color: var(--color-salmon);
}

.cream-bg {
    background-color: var(--color-cream);
}

.white-bg {
    background-color: var(--color-white);
}

.white-border {
    border-color: var(--color-white);
}

.yellow-border {
    border-color: var(--color-yellow);
}

.black-border {
    border-color: var(--color-black);
}

.boulder-border {
    border-color: var(--color-boulder);
}

.flex {
    display: flex;
}

.flex.space-between {
    justify-content: space-between;
}

.flex.beehive-h-center {
    justify-content: center;
}

.flex.beehive-h-start {
    justify-content: flex-start;
}

.flex.beehive-h-end {
    justify-content: flex-end;
}

.flex.v-center {
    align-items: center;
}

.flex.v-start {
    align-items: flex-start;
}

.flex.v-end {
    align-items: flex-end;
}

.flex.v-baseline {
    align-items: baseline;
}

.flex.col,
.flex.column {
    flex-direction: column;
}

.flex.row {
    flex-direction: row;
}

.flex.row-reverse {
    flex-direction: row-reverse;
}

.flex.align-start {
    align-items: flex-start;
}

.flex.wrap {
    flex-wrap: wrap;
}

.block {
    display: block;
}

.inline {
    display: inline;
}

.inline-block {
    display: inline-block;
}

.inline-block.beehive-h-center {
    margin-left: auto;
    margin-right: auto;
}

@media only screen and (min-width: 900px) {
    .lg\:break {
        display: block;
    }

    .lg\:hide {
        display: none;
    }
}

.w-1\/1 {
    width: 100%;
}

.w-3\/5 {
    width: 60%;
}

.w-1\/2 {
    width: 50%;
}

.w-2\/5 {
    width: 40%;
}

.w-1\/3 {
    width: 33.3333%;
}

@media only screen and (max-width: 900px) {
    .md\:w-1\/1 {
        width: 100%;
    }

    .md\:w-3\/5 {
        width: 60%;
    }

    .md\:w-1\/2 {
        width: 50%;
    }

    .md\:w-2\/5 {
        width: 40%;
    }

    .md\:w-1\/3 {
        width: 33.3333%;
    }

    .block.md\:h-center > * {
        margin-left: auto;
        margin-right: auto;
    }

    .md\:flex {
        display: flex;
    }

    .md\:flex.md\:h-center,
    .flex.md\:h-center {
        justify-content: center;
    }

    .md\:flex.md\:col,
    .md\:flex.md\:column,
    .flex.md\:col,
    .flex.md\:column {
        flex-direction: column;
    }

    .md\:hide {
        display: none !important;
    }

    .md\:no-padding {
        padding: 0 !important;
    }
}

@media only screen and (max-width: 900px) and (min-width: 600px) {
    .md\:break {
        display: block;
    }
}

@media only screen and (max-width: 600px) {
    .sm\:w-1\/1 {
        width: 100%;
    }

    .sm\:w-3\/5 {
        width: 60%;
    }

    .sm\:w-1\/2 {
        width: 50%;
    }

    .sm\:w-2\/5 {
        width: 40%;
    }

    .sm\:w-1\/3 {
        width: 33.3333%;
    }

    .sm\:break {
        display: block;
    }

    .sm\:hide {
        display: none !important;
    }
}

.left-align {
    text-align: left;
}

.right-align {
    text-align: right;
}

.center-align {
    text-align: center;
}

.nowrap {
    white-space: nowrap;
}

.underline {
    text-decoration: underline;
}

.uppercase {
    text-transform: uppercase;
}

.weight-book {
    font-family: Circular20WebCyr-Book !important;
}

.weight-book-italic {
    font-family: 'Circular20WebCyr-Book' !important;
    font-style: italic;
}

.weight-medium,
.weight-semi-bold {
    font-family: Circular20WebCyr-Bold !important;
}

.weight-medium-italic,
.weight-semi-bold-italic {
    font-family: 'Circular20WebCyr-BoldItalic' !important;
}

.weight-bold {
    font-family: Circular20WebCyr-Bold !important;
}

.weight-bold-italic {
    font-family: 'Circular20WebCyr-BoldItalic' !important;
}

.weight-black {
    font-family: Circular20WebCyr-Black !important;
}

.weight-black-italic {
    font-family: 'Circular20WebCyr-BlackItalic' !important;
}

@media only screen and (max-width: 900px) {
    .md\:left-align {
        text-align: left;
    }

    .md\:right-align {
        text-align: right;
    }

    .md\:center-align {
        text-align: center;
    }

    .md\:nowrap {
        white-space: nowrap;
    }
}

.beehive-h1,
.beehive-h1 span {
    font-weight: 400;
    font-size: 72px;
    line-height: 1.1;
    letter-spacing: -0.03em;
    margin-top: 0;
    margin-bottom: 0;
    font-family: Circular20WebCyr-Black;
    -webkit-hyphens: none;
    -moz-hyphens: none;
    hyphens: none;
    -webkit-font-smoothing: antialiased;
}

.beehive-h2 {
    font-weight: 400;
    font-size: 48px;
    line-height: 1.1;
    letter-spacing: -0.01em;
    margin-top: 4px;
    margin-bottom: 4px;
    text-transform: none;
    font-family: Circular20WebCyr-Bold;
    -webkit-hyphens: none;
    -moz-hyphens: none;
    hyphens: none;
    -webkit-font-smoothing: antialiased;
}

.beehive-h3,
.beehive-h3 span {
    font-weight: 400;
    font-size: 32px;
    line-height: 1.2;
    letter-spacing: 0em;
    text-transform: none;
    margin-top: 4px;
    margin-bottom: 4px;
    font-family: Circular20WebCyr-Book;
    -webkit-hyphens: none;
    -moz-hyphens: none;
    hyphens: none;
    -webkit-font-smoothing: antialiased;
}

p {
    font-weight: 400;
    font-size: 20px;
    letter-spacing: 0;
    line-height: 1.4;
    margin-top: 2px;
    margin-bottom: 2px;
    font-family: Circular20WebCyr-Book;
    -webkit-font-smoothing: antialiased;
}

@media only screen and (max-width: 900px) {
    .beehive-h1,
    .beehive-h1 span,
    .beehive-h2,
    .beehive-h2 span {
        font-size: 40px;
        line-height: 1.05;
        letter-spacing: 0em;
        margin-top: 4px;
        margin-bottom: 4px;
        font-family: Circular20WebCyr-Book;
    }

    .beehive-h3,
    .beehive-h3 span {
        font-size: 25px;
        line-height: 1.2;
        letter-spacing: 0em;
    }

    p {
        font-size: 18px;
    }
}

@media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) {
        .beehive-h1,
        .beehive-h1 span {
            letter-spacing: -0.035em !important;
        }

        .beehive-h2,
        .beehive-h2 span {
            letter-spacing: -0.02em !important;
        }

        .beehive-h3,
        .beehive-h3 span {
            letter-spacing: -0.015em !important;
        }
    }
}

@media only screen and (max-width: 900px) {
    .beehive-h2,
    .beehive-h2 span,
    .beehive-h3,
    .beehive-h3 span,
    .beehive-h2.weight-medium,
    .beehive-h2.weight-semi-bold,
    .beehive-h3.weight-medium,
    .beehive-h3.weight-semi-bold,
    .beehive-h2.weight-medium span,
    .beehive-h2.weight-semi-bold span,
    .beehive-h3.weight-medium span,
    .beehive-h3.weight-semi-bold span {
        font-family: Circular20WebCyr-Bold !important;
    }
}

// bumble-honey-ambassadors/two-columns-text-section
.moves-for-good .content {
    padding: 100px 10vw;
    gap: 160px;
    overflow: hidden;
}

.moves-for-good-h1 {
    line-height: 0.9;

    @media only screen and (min-width: 600px) {
        white-space: nowrap;
    }
}

@media only screen and (max-width: 1100px) {
    .moves-for-good .content {
        gap: 90px;
    }

    .moves-for-good .w-1\/3:last-child {
        width: 50%;
    }
}

@media only screen and (max-width: 900px) {
    .moves-for-good .content {
        padding: 8vw 10vw 8vw;
        flex-direction: column;
        gap: 10px;
        text-align: center;
        width: 100%;
    }

    .moves-for-good .md\:w-1\/1 {
        margin-top: 8px;
        margin-bottom: 8px;
        max-width: 100% !important;
        width: 100% !important;
    }

    .moves-for-good-h1 {
        font-size: 40px;
        line-height: 40px;
        margin: 0;
    }
}
