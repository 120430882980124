.flexible-image-list {
    display: flex;
    justify-content: center;
    width: 100%;
}

.flexible-image-list__item {
    --flexible-image-item-height: 272px;

    border-radius: 24px;
    overflow: hidden;
    height: var(--flexible-image-item-height);
    width: 100%;

    @include apply-screen-size(desktop-sm) {
        --flexible-image-item-height: 345px;
    }

    .flexible-image-list--full-width & {
        border-radius: 0;
    }
}

.flexible-image-list--three-items {
    .flexible-image-list__item {
        &:first-child {
            display: none;
        }
        &:last-child {
            display: none;
        }

        @include apply-screen-size(desktop-sm) {
            &:first-child {
                display: block;
            }
        }
        @include apply-screen-size(desktop-md) {
            &:first-child,
            &:last-child {
                width: 56%;
            }

            &:last-child {
                display: block;
            }
        }
    }
}

.flexible-image-list--four-items {
    .flexible-image-list__item {
        height: auto;

        &::before {
            content: '';
            padding-top: 100%;
        }

        &:nth-last-child(1) {
            display: none;
        }
        &:nth-last-child(2) {
            display: none;
        }

        @include apply-screen-size(desktop-sm) {

            &:nth-last-child(1) {
                display: block;
            }
            &:nth-last-child(2) {
                display: block;
            }
        }
    }

    .flexible-image-list__item + .flexible-image-list__item {
        margin-left: 24px;

        @include apply-screen-size(desktop-sm) {
            margin-left: 8px;
        }

        .flexible-image-list--full-width & {
            margin-left: 0;
        }
    }
}

.flexible-image-list__item + .flexible-image-list__item {
    @include apply-screen-size(desktop-sm) {
        margin-left: 24px;

        .flexible-image-list--full-width & {
            margin-left: 0;
        }
    }
}

.flexible-image-list--full-width {
    overflow: hidden;

    .media-box.aspect--square {
        margin-bottom: -2px;
    }
}

.flexible-image-list__item-action {
    .media-box {
        transition: 400ms transform ease-in-out;
    }

    &:hover .media-box {
        transform: scale(1.1);
    }
}
