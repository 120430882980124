.ready-to-join {
    background-color: #FFF7DE;
}

.ready-to-join .content {
    padding: 100px 10vw;
    gap: 220px;
    overflow: hidden;
}

.ready-to-join-h2 {
    line-height: 0.9;
    white-space: nowrap;
}

.faq-ctas {
    margin-top: 20px;
    gap: 24px;
}

.faq-ctas a {
    color: var(--color-black);
    border: 1px solid var(--color-black);
    border-radius: 24px;
    padding: 10px 20px;
    min-width: 140px;
    text-align: center;
    font-size: 20px;
    line-height: 20px;
    width: 50%;
    white-space: nowrap;
}

.faq-ctas a:hover {
    background-color: var(--color-yellow);
    /*     border: 1px solid var(--color-yellow); */
}

@media only screen and (max-width: 1100px) {
    .ready-to-join .content {
        gap: 120px;
    }

    .ready-to-join .w-1\/3:last-child {
        width: 50%;
    }
}

@media only screen and (max-width: 900px) {
    .ready-to-join .content {
        padding: 8vw 10vw 3vw;
        flex-direction: column;
        gap: 10px;
        text-align: center;
        width: 100%;
    }

    .ready-to-join .md\:w-1\/1 {
        margin-top: 8px;
        margin-bottom: 8px;
        max-width: 100% !important;
        width: 100% !important;
    }

    .faq-ctas {
        margin-top: 16px;
        margin-bottom: 24px;
    }

    .ready-to-join-h2 {
        font-size: 40px;
        line-height: 40px;
        margin: 0;
    }
}
