@use 'sass:math';

@import '_variables.scss';
@import '_functions.scss';

@mixin size($width, $height: null) {
    @if ($height) {
        width: $width;
        height: $height;
    } @else {
        width: $width;
        height: $width;
    }
}

@mixin lineHeightCrop($line-height) {
    line-height: $line-height;

    &::before {
        content: '';
        display: block;
        height: 0;
        width: 0;
        margin-top: calc((1 - #{$line-height}) * 0.5em);
    }
}

@mixin simpleLink($color: $token-ui-link-color, $hover: $token-ui-link-color-hover, $has-border: true, $has-hover-border: true, $is-native: false) {
    --link-color: #{$color};
    --link-color-hover: #{$hover};
    @if $has-border {
        --link-border-color: #{rgba($color, .3)};
    } @else {
        --link-border-color: #{rgba($color, 0)};
    }
    @if $has-hover-border {
        --link-border-color-hover: currentColor;
    } @else {
        --link-border-color-hover: transparent;
    }

    color: var(--link-color);
    cursor: pointer;

    @if $is-native {
        text-decoration: underline;
        text-decoration-color: var(--link-border-color);
        text-decoration-skip: ink;
        transition: color .2s, text-decoration .2s;
    } @else {
        text-decoration: none;
        border-bottom: 1px solid var(--link-border-color);
        transition: color .2s, border-color .2s;
    }

    &:hover {
        @if $is-native {
            text-decoration-color: var(--link-border-color-hover);
        } @else {
            border-bottom-color: var(--link-border-color-hover);
        }
        color: var(--link-color-hover);
    }
}

// Visually checked at
// http://text-crop.eightshapes.com/?typeface-selection=custom-font&typeface=Average&custom-typeface-name=Circular&custom-typeface-url=&custom-typeface-weight=500&custom-typeface-style=normal&weight-and-style=regular&size=16&line-height=1.5&top-crop=7&bottom-crop=7

@mixin lineHeightAdvanced($line-height: 1.5, $top-adjustment: 0px, $bottom-adjustment: 0px) {
    $top-crop: 6;
    $bottom-crop: 7;
    $crop-font-size: 16;
    $crop-line-height: 1.4;

    // Apply values to calculate em-based margins that work with any font size
    $dynamic-top-crop: math.div(max(($top-crop + ($line-height - $crop-line-height) * ($crop-font-size / 2)), 0), $crop-font-size);
    $dynamic-bottom-crop: math.div(max(($bottom-crop + ($line-height - $crop-line-height) * ($crop-font-size / 2)), 0), $crop-font-size);

    // Mixin output
    line-height: $line-height;

    &::before,
    &::after {
        content: '';
        display: block;
        height: 0;
        width: 0;
    }

    &::before {
        margin-bottom: calc(-#{$dynamic-top-crop}em + #{$top-adjustment});
    }

    &::after {
        margin-top: calc(-#{$dynamic-bottom-crop}em + #{$bottom-adjustment});
    }
}

@mixin trunkText() {
    display: block;
    overflow: hidden;
    line-height: inherit;
    min-width: 0;
    max-width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
}

@mixin scrimGradient($startColor: #000, $direction: 'to bottom') {
    $scrimCoordinates: (
        0: 1,
        19: 0.738,
        34: 0.541,
        47: 0.382,
        56.5: 0.278,
        65: 0.194,
        73: 0.126,
        80.2: 0.075,
        86.1: 0.042,
        91: 0.021,
        95.2: 0.008,
        98.2: 0.002,
        100: 0
    );

    $hue: hue($startColor);
    $saturation: saturation($startColor);
    $lightness: lightness($startColor);
    $opacity: opacity($startColor);
    $stops: ();

    @each $colorStop, $alphaValue in $scrimCoordinates {
        $stop: hsla($hue, $saturation, $lightness, $alphaValue * $opacity) percentage($colorStop*0.01);
        $stops: append($stops, $stop, comma);
    }

    background-image: linear-gradient(unquote($direction), $stops);
}

@mixin respond-to($breakpoint) {
    @if $breakpoint == retina {
        @media only screen and (-webkit-min-device-pixel-ratio: 1.25),
        only screen and (min-device-pixel-ratio: 1.25),
        only screen and (min-resolution: 200dpi),
        only screen and (min-resolution: 1.25dppx) {
            @content
        }
    } @else {
        @media screen and (max-width: $breakpoint) {
            @content
        }
    }
}

@mixin aspectRatioBox($value: 100%) {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: visible;

    &::before {
        content: '';
        display: block;
        flex: none;
        padding-top: $value;
        width: 100%;
    }

    & > *:only-child {
        position: absolute;
        margin: auto;
        max-width: 100%;
        max-height: 100%;
        min-width: 0;
        min-height: 0;
    }
}

@mixin while-sidebar-collapsed($breakpoint: $token-breakpoint-desktop-narrow) {
    @media screen and (max-width: $breakpoint) {
        .sidebar:not(.is-expanded) & {
            @content
        }
    }
}

@mixin iconButtonColor($is-light: false) {
    $icon-button-color: $token-color-gray-dark;

    @if $is-light == true {
        $icon-button-color: $token-color-gray;
    }

    color: $icon-button-color;

    &:hover {
        color: color-hover-intense($icon-button-color);
    }

    &:active {
        color: color-active-intense($icon-button-color);
    }
}

@function relative-line-height($font-size, $line-height) {
    @return ceil(math.div($line-height, $font-size) * 1000) * 0.001;
}
