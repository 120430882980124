.schema-faq {
    --section-vertical-gap: 12px;
    padding-bottom: var(--section-vertical-gap);

    @include apply-screen-size(tablet-md) {
        --section-vertical-gap: 24px;
    }
}
.schema-faq__question {
    scroll-margin-top: calc(var(--header-height) + 16px);

    .h3 {
        @include apply-screen-size(desktop-md) {
            font-size: 32px;
            line-height: 1.2;
            letter-spacing: 0em;
            text-transform: none;
        }
    }

    &::after {
        content: '';
        display: block;
        width: calc(100% - 20px);
        height: 1px;
        background-color: var(--color-primary);
        margin: 0 20px calc(var(--section-vertical-gap) * 2);
    }
}

.schema-faq-question__action {
    display: flex;
    justify-content: space-between;

    .h3 {
        margin: 0;
    }

    &:hover .icon-x {
        transform: rotate(15deg);
    }
}

.schema-faq-question__action-icon {
    width: 36px;
    height: 36px;

    .icon-x {
        transform: rotate(0deg);
        transform-origin: 50%;
        transition: transform 0.2s;
    }

    &.is-active {
        .icon-x {
            transform: rotate(45deg);
        }
    }
}

.schema-faq__question + .schema-faq__question {
    margin-top: var(--section-vertical-gap);
}

.schema-faq__description {
    padding: 8px 24px;
}

.schema-faq__question-answer {
    overflow: hidden;
    max-height: 0px;
    padding-bottom: 0px;
    transition: max-height 400ms;
    margin-top: 16px;
    margin-bottom: 16px;


    &.is-open {
        max-height: 2000px;
    }
}

.schema-faq__question-answer-faq .paragraph {
    margin-top: 8px;
    padding: 0;
}

.schema-faq--ordered {
    list-style: auto;
}
