.what {
    overflow: hidden;

    .content {
        padding: 100px 0;
        width: 100%;

        @media only screen and (max-width: 600px) {
            padding: 50px 0;
        }
    }

    .beehive-h1 {
        padding-left: 10vw;
        padding-right: 10vw;
        margin-bottom: 54px;

        @media only screen and (max-width: 600px) {
            font-size: 40px;
            line-height: 42px;
            margin-bottom: 48px;
        }
    }
}

.what-do {
    margin-top: 80px;
}

.do-title {
    opacity: 0;
    transition: opacity 0.2s;
    width: 100%;
    margin-bottom: 36px;
    margin-top: 32px;

    .glide__slide--active & {
        opacity: 1;
    }

    .glide__moving .glide__slide--active & {
        opacity: 0 !important;
    }

    @media only screen and (max-width: 600px) {
        margin-bottom: 42px;
    }
}

.post-title.do-title {
    margin-bottom: 36px;

    @media only screen and (max-width: 600px) {
        margin-bottom: 42px;
    }
}

.do-ctas {
    margin-top: 54px;
    gap: 24px;
    max-width: 420px;
    width: 80%;
    margin-left: auto;
    margin-right: auto;

    a {
        color: var(--color-black);
        border: 1px solid var(--color-black);
        border-radius: 24px;
        padding: 10px 20px;
        min-width: 140px;
        text-align: center;
        font-size: 20px;
        line-height: 20px;
        width: 50%;
        white-space: nowrap;

        &:hover {
            background-color: var(--color-yellow);
            /*     border: 1px solid var(--color-yellow); */
        }
    }

    @media only screen and (max-width: 900px) {
        margin-top: 54px;
        margin-bottom: 24px;
    }
}

// @TODO: Uncomment, when refactor post-carousel.js
// .wrapper {
//     width: 100vw;
//     max-width: 100vw;
// }
// .glide__slides {
//     margin-bottom: attr(bulletMargin); // 66px;
// }
// .glide__slide {
//     display: flex;
//     flex-direction: column;
// }
// .glide__slide > * {
//     max-width: 100%;
// }
// .post-image {
//     max-width: 100%;
//     width: 650px;
//     min-width: 100%;
//     height: 500px;
//     max-height: 100vw;
//     border-radius: 24px;
//     transform: scale(0.995);
//     box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0);
//     transition: box-shadow 0.2s, transform 0.2s;
//     background-size: cover;
//     background-repeat: no-repeat;
//     background-position: center;
//     background-color: var(--color-yellow);
// }
// .post-imager:hover {
//     transform: scale(1);
//     box-shadow: 1px 1px 4px 1px rgba(0, 0, 0, 0.1);
// }
// .post-title {
//     text-align: center;
//     font-family: Circular20WebCyr-Bold;
//     margin: 24px auto 0;
//     line-height: 1em !important;
//     width: 650px;
//     padding-left: 0.01vw;
//     padding-right: 0.01vw;
//     padding-bottom: 0.1em;
// }
// .post-title-link {
//     color: inherit;
//     text-decoration: none;
// }
// .post-title-link + .post-description,
// .post-title + .post-description {
//     margin-top: 20px;
// }
// .post-description {
//     width: 650px;
//     margin-left: auto;
//     margin-right: auto;
// }
// .glide__controls {
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     gap: 32px;
// }
// glide__arrows {
//     display: flex;
// }
// .glide__arrow {
//     background: none;
//     border: none;
//     cursor: pointer;
// }
// .glide__bullets {
//     padding-bottom: 3px;
//     display: flex;
//     justify-content: center;
// }
// .glide__bullet {
//     width: 8px;
//     height: 8px;
//     background-color: rgba(128, 128, 128, 0.3);
//     border-radius: 50px;
//     border: none;
//     padding: 0;
//     transition: width 0.2s, margin 0.2s;
//     cursor: pointer;
// }
// .glide__bullet:focus {
//     outline: none;
// }
// .glide__bullet:not(.glide__bullet--active) {
//     margin: 0 20px;
// }
// .glide__bullet--active {
//     background-color: var(--color-yellow);
//     margin: 0 0;
//     width: 48px;
// }

// @media only screen and (max-width: 600px) {
//     .glide__slides {
//         margin-bottom: attr(bulletMarginSm); // 8}px;
//     }
//     .glide__controls {
//         transform: scale(0.6);
//     }
// }

// slider on highlight background
#highlights .content {
    padding: 100px 0;
    overflow: hidden;
}

#highlights h1 {
    margin-bottom: 52px;
}

.highlight-slide {
    width: 100%;
    padding: 0 10vw;
    max-width: 1400px;
    margin-left: auto;
    margin-right: auto;
    box-sizing: content-box;
}

.spotlight-img {
    max-width: 480px;
}

.spotlight-img img {
    width: 100%;
    border-radius: 16px;
}

.highlight-about {
    padding: 32px 0px 24px 80px;
}

.highlight-m-header {
    margin-bottom: 24px;
}

.highlight-name {
    gap: 16px;
}

.highlight-q {
    margin-bottom: 16px;
}

.highlight-a {
    padding-left: 24px;
    position: relative;
    max-width: 600px;
}

.highlight-a:after {
    content: '';
    height: 100%;
    width: 3px;
    background-color: var(--color-pollen);
    left: 0;
    top: 2px;
    position: absolute;
    border-radius: 1px;
}

@media only screen and (max-width: 900px) {
    #highlights .content {
        padding: 8vw 0;
    }

    #highlights h1 {
        margin-bottom: -12px;
    }

    .highlight-slide {
        padding: 0 24px;
    }

    .highlight-about {
        padding: 32px 0px 24px 0px;
    }

    .highlight-m-header {
        display: flex;
        align-items: flex-end;
        gap: 16px;
    }

    .highlight-m-header .highlight-name {
        flex-direction: column;
        gap: 0;
    }

    .highlight-m-header .highlight-name > h2 {
        font-size: 25px;
    }

    .highlight-m-header .highlight-name > * {
        margin: 0;
    }

    .highlight-q {
        padding-right: 36px;
    }
    .highlight-a {
        padding-left: 12px;
        padding-right: 36px;
    }

    .highlight-a:after {
        width: 1px;
    }
}
