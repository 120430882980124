.page-section {
    display: block;

    &:empty {
        display: none;
    }

    .cta-box {
        padding: 0;
    }
}

.page-section--article-intro {
    --page-section-border-radius: 24px;
    background-color: #fefbed;
    border-radius: var(--page-section-border-radius);

    &.page-section--padded {
        padding-left: 16px;
        padding-right: 16px;
    }

    &.page-section--limited-width {
        max-width: 1016px;
        margin-top: -24px;
        margin-bottom: 48px;
        margin-left: 20px;
        margin-right: 20px;
    }

    @include apply-screen-size(tablet-md) {
        --page-section-border-radius: 40px;

        &.page-section--padded {
            padding-left: 90px;
            padding-right: 90px;
        }

        &.page-section--limited-width {
            max-width: 1016px;
            margin-top: -48px;
            margin-bottom: 72px;
            margin-left: auto;
            margin-right: auto;
        }
    }
}

.page-section--limited-width {
    max-width: 840px;
    margin: 0 auto;
}

.page-section--image {
    --section-vertical-gap: 48px;

    max-width: 870px;
    margin: var(--section-vertical-gap) auto;

    @include apply-screen-size(tablet-md) {
        --section-vertical-gap: 72px;
    }
}

.page__content + .page__footer {
    --section-vertical-gap: 48px;

    margin-top: var(--section-vertical-gap);

    @include apply-screen-size(tablet-md) {
        --section-vertical-gap: 72px;
    }
}

.page-section--article-splash-screen .page__media-image {
    min-width: 50vw;
}

.page-section--primary {
    background-color: var(--color-primary);
}

.page-section--no-bg {
    position: relative;
}

.page-section--primary-partly {
    &::before {
        content: '';
        position: absolute;
        top: 0;
        width: 100%;
        height: 40%;
        background-color: var(--color-primary);
    }

    .page-section--no-bg &::before {
        content: none;
    }
}

.page-section--secondary {
    --text-color: var(--color-primary);

    background-color: var(--color-base);
    color: #fff;
}

.page-section--accent {
    background-color: #ffedb4;
}

.page-section--padded {
    padding: var(--content-section-padding-vertical) 0;
}

.page-section--footer-cta {
    padding: 0 20px;

    @include apply-screen-size(tablet-md) {
        padding: 0;
    }

    &.page-section--padded {
        padding: var(--content-section-padding-vertical) 20px;

        @include apply-screen-size(tablet-md) {
            padding: var(--content-section-padding-vertical) 0;
        }
    }
}

.page-section--bff-form {
    display: flex;
    justify-content: center;

    .page-section__inner {
        background-color: #ffde3b;
        border: 2px solid #444647;
        padding: 18px 22px;
        margin: 0 var(--content-section-padding-horizontal);

        @include apply-screen-size(tablet-md) {
            padding: 32px;
        }
        @include apply-screen-size(desktop-sm) {
            padding: 32px 64px;
            max-width: calc(var(--content-max-width) - 58px);
        }
    }

    .columns {
        margin: 0 auto;

        @include apply-screen-size(tablet-md) {
            align-items: flex-start;
        }
    }

    .link {
        color: inherit;
        text-decoration: underline;
    }
}

.page-section--bff-buttertop {
    --content-section-padding-vertical: 32px;
    --content-section-padding-horizontal: 24px;

    position: relative;
    background-color: #1431B1;
    color: #fff;
    border-bottom: 2px solid #444647;
    padding: var(--content-section-padding-vertical) var(--content-section-padding-horizontal);
    display: flex;
    width: 100%;
    isolation: isolate;
    contain: content;

    &::before {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        background-color: #FFE9AD;
        width: 100%;
        height: 20%;
        border-radius: 36px 36px 0 0;
    }

    .columns {
        width: 100%;
        max-width: 1500px;
        margin: 0 auto;
        align-items: flex-start;

        @include apply-screen-size(desktop-md) {
            max-height: 1000px;
        }
    }

    .columns__item {
        flex: 1 0 calc(50% - 16px);
    }

    @include apply-screen-size(tablet-md) {
        --content-section-padding-vertical: 24px;
        --content-section-padding-horizontal: 32px;
    }
    @include apply-screen-size(desktop-sm) {
        --content-section-padding-vertical: 50px;
        --content-section-padding-horizontal: 50px;

        min-height: calc(100vh - 70px);
    }
    @include apply-screen-size(desktop-md) {
        isolation: isolate;
        contain: content;
        min-height: 100%;
        height: 100%;
        max-height: 1000px;
    }
}

.page-section__video {
    position: absolute;
    top: 4%;
    left: 25%;
    width: 51%;
    border-radius: 5vw;

    @include apply-screen-size(tablet-md) {
        top: 6%;
        left: 18%;
        width: 19%;
        border-radius: 24px;
        border: 1px solid #444647;
        z-index: 1;
    }
}

.page-section__social-sharing-block {
    position: absolute;

    &.has-shadow {
        top: 53%;
        left: 36%;
        transform: scale(1.15);

        .social-sharing-block {
            border: none;
            background-color: #ffe9ad;
        }
    }

    &.is-tilted {
        top: 51.65%;
        left: 55%;
        transform: rotate(13deg) scale(0.74);
        min-width: 180px;
    }
}

.page-section__title {
    --page-section-title-gap: 12px;

    margin-bottom: var(--page-section-title-gap);

    @include apply-screen-size(tablet-md) {
        --page-section-title-gap: 28px;
    }
    @include apply-screen-size(desktop-md) {
        --page-section-title-gap: 48px;
    }
}

.page-section__subtitle {
}

.page-section__cta {
    --page-section-cta-gap: 28px;

    margin-top: var(--page-section-cta-gap);

    @include apply-screen-size(tablet-md) {
        --page-section-cta-gap: 24px;
    }
    @include apply-screen-size(desktop-md) {
        --page-section-cta-gap: 48px;
        display: flex;
    }
}

.page-section__inner {
    position: relative;
    max-width: var(--content-max-width);
    margin: 0 auto;
    padding: 0 var(--content-section-padding-horizontal);

    .page-section--mobile-full-width & {
        @include apply-screen-size(tablet-md) {
            padding: 0 var(--content-section-padding-horizontal);
        }

        padding: 0;
    }
}

.page-section + .page-section {
    --page-section-gap: 48px;
    margin-top: var(--page-section-gap);

    @include apply-screen-size(tablet-md) {
        --page-section-gap: 56px;
    }
    @include apply-screen-size(desktop-sm) {
        --page-section-gap: 70px;
    }
}
