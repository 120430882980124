@mixin apply-translate-animation(
    $xStart: 0,
    $yStart: 0,
    $xStop: 50%,
    $yStop: 0,
    $scaleStartX: 1,
    $scaleStartY: 1,
    $scaleStopX: 1,
    $scaleStopY: 1
) {
    0% {
        transform: translate($xStart, $yStart) scale($scaleStartX, $scaleStartY);
    }

    25%,
    75% {
        transform: translate($xStop, $yStop) scale($scaleStopX, $scaleStopY);
    }

    100% {
        transform: translate($xStart, $yStart) scale($scaleStartX, $scaleStartY);
    }
}

.page {
    display: flex;
    flex-direction: column;
    min-height: 100vh;

    .ie11 & {
        display: none;
    }
}

.page__breadcrumbs {
    --breadcrumbs-bottom-gap: 8px;
    margin-bottom: var(--breadcrumbs-bottom-gap);

    @include apply-screen-size(desktop-sm) {
        --breadcrumbs-bottom-gap: 28px;
    }
}

.page__progress {
    position: fixed;
    z-index: var(--page-progress-z-index);
    top: 0;
    right: 0;
    left: 0;
}

.page__social-links {
    position: fixed;
    z-index: var(--page-social-links-z-index);
    bottom: 16px;
    left: 50%;
    transform: translateX(-50%);
}

.page__section {
    display: block;

    &:empty {
        display: none;
    }
}

.page__media {
    position: relative;
    z-index: -1; // fix for overflow of next element
    overflow: hidden;
    padding-top: 66.67%;
    background-color: var(--color-primary-light);
    object-fit: cover;
}

.page__media-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    // This overrides the styles set by NextJS
    min-height: auto !important;
    max-height: unset !important;
    height: auto !important;
    margin: 0 !important;

    .page__section--article-splash-screen & {
        min-width: 50vw;
    }
}

.page__main {
    flex: 1 1 auto;
}

.page__article {
    display: block;
}

.page__article-social {
    display: flex;
    justify-content: center;
}

.page__content {
    flex: 1 0 auto;

    .has-no-scroll & {
        overflow: hidden;
        max-height: 100vh;
        pointer-events: none;

        .modal {
            pointer-events: all;
        }
    }
}

.page__section--primary {
    --text-color: #fff;

    background-color: var(--color-primary);
    color: #fff;
}

.page__section--secondary {
    --text-color: #fff;

    background-color: var(--color-base);
    color: #fff;
}

.page__section--padded {
    padding: var(--content-section-padding-vertical) 0;
}

.page__section--no-margin-from-top {
    margin-top: calc(-1 * var(--content-section-padding-vertical));
}

.page__section--no-gaps {
    overflow: hidden;
    margin: 0;

    .cta-box {
        margin: 0;
        padding: 0;
    }
}

.page__section--no-gaps + .page__section--padded {
    padding-top: calc(-2 * var(--content-section-padding-vertical));
}

.page__section--aligned-center-inner {
    .page__section-inner {
        display: flex;
        justify-content: center;
    }
}

.page__content--align-center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.page__aside-divider {
    padding-top: 24px;
    border-bottom: 1px solid #e7e7e7;
}

.page__section-inner {
    max-width: var(--content-max-width);
    margin: 0 auto;
    padding: 0 var(--content-section-padding-horizontal);

    .page__section-mobile-full-width & {
        @include apply-screen-size(tablet-md) {
            padding: 0 var(--content-section-padding-horizontal);
        }

        padding: 0;
    }
}

.page__section--full-width {
    width: 100%;
}

.page--v-aligned {
    .page__content {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .page__section {
        width: 100%;
    }
}

.page__section--splash-screen + .page__section--categories-menu {
    margin-top: 32px;
}

.page__article-content + .page__article-social {
    margin-top: 32px;
}

.page__main-section + .page__main-section {
    margin-top: 24px;
}

.page__topic-content + .page__topic-share {
    margin-top: 32px;
}

.page__topic-breadcrumbs + .page__topic-content {
    margin-top: 24px;
}

.page__section-breadcrumbs + .page__section-list {
    margin-top: 32px;
}

.page__header--center {
    display: flex;
    justify-content: center;
}

.page--fixed {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    width: 100%;
    min-height: 100%;
    // iphone 11+
    padding-right: max(8px, env(safe-area-inset-right));
    padding-bottom: max(8px, env(safe-area-inset-bottom));
    padding-left: max(8px, env(safe-area-inset-left));

    .page__content {
        display: flex;
        flex-direction: column-reverse;
        justify-content: center;
        width: 100%;
    }
}

@media screen and (max-width: desktop-sm-max) {
    .page__aside {
        margin-right: calc(var(--horizontal-gap) * -1);
        margin-left: calc(var(--horizontal-gap) * -1);
    }

    .page__aside-inner {
        padding: 0 var(--horizontal-gap);

        &.is-desktop-only {
            display: none;
        }
    }

    .page__article + .page__aside {
        margin-top: 48px;
    }

    .page__aside + .page__cta {
        margin-top: 48px;
    }

    .page__aside + .page__main {
        margin-top: var(--vertical-gap-large);
    }
}

.page--header-fixed {
    .page__header {
        @include apply-screen-size(desktop-sm) {
            top: 40px;
            left: 40px;
            transform: none;
        }

        position: fixed;
        z-index: 999; // temporary
        top: 32px;
        left: 50%;
        transform: translateX(-50%);
    }

    .page__header + .page__content {
        margin-top: 0;
    }

    .logotype__image {
        transition: color 200ms ease-in-out;
    }
}

.page--footer-fixed {
    .footer-fixed {
        z-index: 3;
    }

    @media screen and (max-width: 1140px) {
        .footer-fixed {
            left: 0;
            flex-direction: column;
            align-items: center;
            width: 100%;
        }
    }
}

@include apply-screen-size(desktop-sm) {
    .page__section-content {
        padding-right: var(--horizontal-gap-large);
        padding-left: var(--horizontal-gap-large);
    }

    .page__section-content--article {
        display: flex;
    }

    .page__article {
        flex: 1 1 auto;
        margin: -128px 32px 0;
        padding: var(--page-article-gap);
        background: #fff;
    }

    .page__aside {
        flex: 0 0 auto;
        width: 184px;

        .article-preview__media-image {
            min-width: 184px;
        }
    }

    .page__aside-inner {
        padding: 0;
    }

    .page__aside-divider.is-mobile-only {
        display: none;
    }

    .page__anchor--mobile-only {
        display: none;
    }

    .page__cta {
        display: none;
    }

    .page__article-content + .page__article-social {
        margin-top: 48px;
    }

    .page__aside + .page__main {
        margin-top: 0;
        margin-left: var(--horizontal-gap-large);
    }

    .page__main-section + .page__main-section {
        margin-top: 32px;
    }

    .page__section--article-splash-screen .page__section-content {
        overflow: hidden;
        min-height: 40vh;
        max-height: calc(100vh - 200px);
    }

    .page__corner {
        animation-duration: 3s;
    }
}

@media screen and (min-width: $media-desktop) {
    .page__aside {
        width: 208px;

        .article-preview__media-image {
            min-width: 208px;
        }
    }

    .page__main {
        max-width: 608px;
    }

    .page__aside + .page__main {
        margin-left: auto;
    }
}

.page--bff-buttertop {
    background-color: #F5F1E2;

    .button--cta-read-more {
        background-color: #ffde3b !important;
    }
}
